import { Component, Input, OnInit } from '@angular/core';
import { TrainerRole } from 'src/app/types/trainer.interface';

@Component({
  selector: 'app-trainer',
  templateUrl: './trainer.component.html',
  styleUrls: ['./trainer.component.css']
})
export class TrainerComponent implements OnInit{

  /**
   * Additional classes for the trainer
   */
  @Input() css?: string;

  /**
   * URL of the image
   */
  @Input() image?: string;

  /**
   * Name of the trainer shown below the image
   */
  @Input() name?: string;

  /**
   * Role of the trainer shown below the name
   */
  @Input() role?: string;

  /**
   * Link for the "Mehr erfahren" button
   */
  @Input() link?: string;

  public _role: string = '';


  ngOnInit(): void {
    this._role = Object.values(TrainerRole)[Object.keys(TrainerRole).indexOf(this.role!)];
  }

}
