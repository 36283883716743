<ng-container *ngIf="(auth.isAuthenticated$ | async) === false; else adminMenu">
  <!-- TODO: Remove also h-16? -->
  <nav class="bg-white py-2 px-8 flex items-center h-16 lg:px-16">
    <div class="grow flex items-center">
      <a routerLink="/home"><img src="../../../assets/logos/HKS-No-Font.svg" class="h-12 lg:h-16" height="64px" alt="Hundeschule Karin Schwarz"/></a>
      <a routerLink="/home" class="hidden xl:block px-4 xl:text-xl">Hundeschule <span class="font-bold">Karin Schwarz</span></a>
    </div>
  
    <div class="lg:hidden p-2 cursor-pointer rounded-md hover:bg-gray-100 active:bg-gray-200" (click)="opened = !opened">
      <app-icon *ngIf="!opened" icon="Hamburger" />
    </div>
  
    <div class="px-4 hidden lg:flex justify-evenly">
      <!-- Nav-Bar Link-List -->
      <a *ngFor="let link of links" [routerLink]="link.link" [routerLinkActive]="'underline'"
        [routerLinkActiveOptions]="linkStyle" class="text-gray-800 p-8 md:p-6 underline-offset-8 whitespace-nowrap  hover:text-black hover:underline" [ngClass]="'peer/'+link.name">
        {{ link.name }}
      </a>
      <!-- Courses Droplist -->
      <div class="hidden font-normal flex-wrap flex-row-reverse justify-end xl:flex-row xl:flex-nowrap xl:justify-center z-40 p-8 gap-32 gap-y-16 dropdown-content absolute bg-white w-full top-16 left-0 right-0 shadow peer-hover/Kurse:flex hover:flex">
        <div *ngFor="let category of (categories$ | async)" class="flex flex-col gap-6">
          <p class="font-semibold text-xl">{{category.name}}</p>
          <div class="flex gap-8 font-thin">
            <div class="flex flex-col gap-4 text-gray-800"
              *ngFor="let courseChunk of ((courses$ | async) | filter: filterFunction : category.id)">
              <a *ngFor="let course of courseChunk" [routerLink]="'/kurse/'+course.name" class="hover:text-black">{{course.title}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
  
  <div *ngIf="opened" class="lg:hidden bg-white fixed left-0 top-0 right-0 bottom-0 z-50 flex flex-col px-8 ">
    <div class="flex justify-end">
      <div class="p-2 cursor-pointer rounded-md hover:bg-gray-100 active:bg-gray-200" (click)="opened = !opened">
        <app-icon icon="Close" />
      </div>
    </div>
    <div class="h-full flex flex-col justify-center items-center gap-10 pb-16">
      <a *ngFor="let link of links" [routerLink]="link.link" [routerLinkActive]="'underline underline-offset-8'" [routerLinkActiveOptions]="linkStyle" class="text-xl" (click)="opened = !opened">
        {{ link.name }}
      </a>
    </div>
  </div>
</ng-container>
<ng-template #adminMenu>
  <nav class="bg-white border-b border-b-gray-100 py-2 px-8 flex items-center h-16 lg:h-24 lg:px-16">
    <div class="grow flex items-center cursor-pointer" [routerLink]="'admin'">
      <a [routerLink]="'admin'"><img src="../../../assets/logos/HKS-No-Font.svg" class="h-12 lg:h-16" height="64px" alt="Hundeschule Karin Schwarz"/></a>
      <p class="px-4 text-2xl">Admin-Menü</p>
    </div>
    <app-auth-button></app-auth-button>
  </nav>
</ng-template>

<router-outlet></router-outlet>

<div *ngIf="(topOfPage$ | async) === false" class="fixed bottom-8 right-8">
  <button (click)="scrollTop()" class="bg-primary-500 hover:bg-primary-400 active:bg-primary-300 shadow-md p-2 rounded-full w-16 h-16 flex items-center justify-center">
    <app-icon icon="Up" class="w-8 h-8"/>
  </button>
</div>

<footer class="bg-concrete-100 " *ngIf="(auth.isAuthenticated$ | async) === false">
  <div class="w-full flex flex-col items-center justify-center text-center p-20 gap-6 sm:gap-16" id="contact">
    <div class="flex flex-col gap-4">
    <h1 class="text-4xl font-semibold">Kontakt</h1>
    <p class="hidden sm:block text-base font-thin">Ich freue mich, Sie und ihren Hund kennenzulernen!</p>
  </div>

  <div class="flex flex-col sm:flex-row gap-16 sm:gap-8 md:gap-20 lg:gap-40 xl:gap-60 text-sm">
    <div class="flex flex-col justify-center items-center">

      <app-icon icon="Location" class="mb-8"/>
      <p class="break-keep">Hundeschule&nbsp;Karin&nbsp;Schwarz</p>
      <p class="break-keep">Wellingshof&nbsp;1</p>
      <p class="break-keep">73660&nbsp;Urbach</p>
    </div>
    <div class="hidden sm:flex flex-col justify-center items-center">
      <img src="../../../assets/logos/HKS-Logo.svg" alt="Hundeschule Karin Schwarz - Logo" class="w-full w-auto sm:max-w-[200px] mb-8"/>
        <div class="w-full flex justify-between">
          <a href="mailto:karin.schwarz@hundeschule-karinschwarz.de"><app-icon icon="Mail"/></a>
          <a href="https://www.facebook.com/hundeschulekarinschwarz/" target="_blank"><app-icon icon="Facebook"/></a>
          <a href="https://www.instagram.com/hundeschulekarinschwarz/" target="_blank"><app-icon icon="Instagram"/></a>
        </div>
    </div>
    <div class="flex flex-col justify-center items-center">
      <app-icon icon="Phone" class="mb-8"/>
      <a href="tel:+491608083992"><p class="break-keep">Mobil&nbsp;0160-8083992</p></a>
      <a href="tel:07181473466"><p class="break-keep" >Tel.&nbsp;07181-473466</p></a>
    </div>
    <div class="sm:hidden flex justify-between">
      <a href="mailto:karin.schwarz@hundeschule-karinschwarz.de"><app-icon icon="Mail"/></a>
      <a href="https://www.facebook.com/hundeschulekarinschwarz/" target="_blank"><app-icon icon="Facebook"/></a>
      <a href="https://www.instagram.com/hundeschulekarinschwarz/" target="_blank"><app-icon icon="Instagram"/></a> 
    </div>
  </div>
  </div>
    
  <div class="flex flex-wrap justify-center text-[10px] text-center p-2">
    <p>&copy; Copyright {{year}} Hundeschule Karin Schwarz | <a routerLink="impressum">Impressum</a> | <a routerLink="privacy">Datenschutz</a> | <button routerLink="admin">Anmelden</button> | <a href="#" onclick="CCM.openWidget(); return false;">Cookie Präferenzen</a></p>
  </div>
</footer>
