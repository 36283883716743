<!-- Trainer-Details -->
<app-section css="bg-primary-50 relative">
    <div class="absolute left-4 top-4">
        <app-back-button />
    </div>
    <div class="flex-wrap-reverse sm:flex-nowrap w-full flex items-center gap-10 justify-center">
        <div class="w-full sm:w-1/2 flex flex-col text-center" [ngClass]="trainer?.splash_image ? 'sm:text-left' : 'sm:text-center'">
            <h1 class="text-4xl lg:text-6xl font-semibold">{{trainer?.name}}</h1>
            <p class="w-full pt-6 lg:pt-12 font-light">{{trainer?.description}}</p>
        </div>
        <div class="flex w-full sm:w-1/2 justify-end" *ngIf="trainer?.splash_image" >
            <img [src]="trainer?.splash_image" class="object-contain"
                style="mask-image: url(../../../assets/images/Maske.svg); mask-repeat: no-repeat; -webkit-mask-image: url(../../../assets/images/Maske.svg); -webkit-mask-repeat: no-repeat;"
                placeholder="logo.png" [alt]="trainer?.name" />
        </div>
    </div>
</app-section>

<!-- Dogs -->
<app-section css="flex flex-col w-full items-center gap-12" *ngIf="dogs.length > 0">
    <h1 class="text-4xl text-center lg:text-6xl font-semibold">An meiner Seite</h1>
    <div class="w-full flex justify-center">
        <div class="flex flex-wrap flex-col lg:flex-row justify-start gap-20">
            <div class="xl:w-[calc(50%-2.5rem)]" *ngFor="let dog of dogs" [ngClass]="dogs.length<2 ? 'grow' : ''">
                <app-dog [name]="dog.name" [description]="dog.description" [image]="dog.avatar"
                    [breed]="dog.breed"></app-dog>
            </div>
        </div>
    </div>
</app-section>

<!-- Courses that can be attended, taught by the trainer -->