import { Component } from '@angular/core';

@Component({
  selector: 'app-karin-details',
  templateUrl: './karin-details.page.component.html',
  styleUrls: ['./karin-details.page.component.css']
})
export class KarinDetailsPageComponent {

  public examns = [
    {
      year: 2024,
      examn: [
        {title: 'Junghunde - Praxisseminar mit Kurskonzept', speaker: 'Mag. A Ursula Aigner, Dr. Barbara Schöning'},
        {title: 'Jagdverhalten für Hundetrainerinnen/ Hospitanzwoche', speaker: 'Pia Gröning'},
        {title: 'A modern approach to ethical animal training methods', speaker: 'Eduardo J. Fernandez, PhD'},
      ]
    },
    {
      year: 2023,
      examn: [
        {title: '7. Wissenschaftlichen Symposium des BHV', speaker: 'Prof. Dr. Ilona Croy, Amelie Göschl, Mary Hunter, Prof. Dr. Jesús Rosales-Ruiz, Karina Mahnke, Dr. Jon Bowen, Prof. Dr. Kurt Kotrschal'},
        {title: 'BHV Dogdance - Trainerin Zertifizierung', speaker: 'Jessy Lang und Beate Lambrecht'},
        {title: 'Jagdverhalten für Hundetrainer', speaker: 'Pia Gröning und Anke Lehne'},
      ]
    },
    {
      year: 2022,
      examn: [
        {
          title: 'Dogdance / HTM',
          speaker: 'Monika Gehring, Alexandra Vavrova, Jennifer Fraser'
        },
        {
          title: 'Hoopers',
          speaker: 'Angelika und Werner Augustin'
        }
      ]
    },
    {
      year: 2021,
      examn: [
        {
          title: 'Hoopers Trainerausbildung nach NYD',
          speaker: 'Angelika und Werner Augustin'
        },
        {
          title: '6. Wissenschaftliches BHV Symposium',
          speaker: 'Xenia Katzurke, Dr. Stefanie Riemer, Dr. Mechthild Wiegard, Susanne Schicho, Judith Schönenstein, Julia Schulz, Prof. Dr. Kurt Kotrschal'
        }
      ]
    },
    {
      year: 2019,
      examn: [
        {
          title: 'Trainingswoche Aggressionsverhalten beim Hund',
          speaker: 'Normen Mrozinski'
        },
        {
          title: 'Monster, Memme, Mittäter',
          speaker: 'Normen Mrozinski'
        },
        {
          title: 'Obedience',
          speaker: 'Bettina Ogris'
        }
      ]
    },
    {
      year: 2018,
      examn: [
        {
          title: 'Praxisseminar für Dummytrainer',
          speaker: 'Tina Schnatz'
        },
        {
          title: 'Dummytraining unterrichten Teil I',
          speaker: 'Tina Schnatz'
        },
      ]
    },
    {
      year: 2017,
      examn: [
        {
          title: 'Targets für alle Sinne',
          speaker: 'Katrin Heimsath'
        }
      ]
    },
    {
      year: 2015,
      examn: [
        {
          title: 'BHV Workshop »Exploring Shaping and Behavior Using PORTL«',
          speaker: 'Prof. Dr. Jesús Rosales-Ruiz + Mary Hunter / University of North Texas, Denton, Texas'
        },
        {
          title: 'Wissenschaftliches Symposioum des BHV',
          speaker: 'Berufsverband der Hundeerzieher:innen'
        },
      ]
    },
    {
      year: 2014,
      examn: [
        {
          title: 'IHK-Lehrgänge divers',
          speaker: 'Dr. Sabine Seufert, Marcel Gäding, Simone Pohl, Dr. Christiane Wergowski'
        },
        {
          title: 'IHK-Zertifizierung zur “ Hundetrainerin und Verhaltensberaterin IHK“',
          speaker: 'IHK Potsdam'
        },
        {
          title: 'Training in einer anderen Dimension',
          speaker: 'Viviane Theby'
        }
      ]
    },
    {
      year: 2013,
      examn: [
        {
          title: 'Tiergestützte Intervention',
          speaker: 'Dr. Andrea Beetz / Cornelia Drees'
        },
        {
          title: 'Erste Hilfe Kurs für Tiere',
          speaker: 'Tierrettung Mittlerer Neckar/ Frau Völker'
        },
        {
          title: '„Wieder sozial…“ Verbesserung der sozialen Fähigkeiten des Hundes bis hin zur Integration in eine bestehende Hundegruppe',
          speaker: 'Mirjam Cordt'
        },
        {
          title: 'Rechtliche Grundlagen der Tierhaltung, Tierrecht und seine Geschichte',
          speaker: 'Marcel Gäding, IHK Potsdam'
        },
        {
          title: 'Unternehmensführung und Management',
          speaker: 'Marcel Gäding, IHK Potsdam'
        },
      ]
    },
    {
      year: 2012,
      examn: [
        {
          title: 'Kynologische Arbeitstagung VDH',
          speaker: 'Kommunikation Mensch/ Hund – Dr. Sabine Seufert, DOK Augenuntersuchung/ ausgesuchte Augenerkrankungen junger Hunde – Dr. Wolfgang Sinzinger, Selektionssignaturen, genomische Zuchtwerte, genomische Selektion – Wie kann die Hundezucht die neuen Erkenntnisse der Hundegenomik umsetzen – Prof. Dr. Ottmar Distl, Zucht und Handel von Rassehunden aus Sicht der Landes-Tierschutzbeauftragten von Baden-Württemberg – Dr. Cornelie Jäger'
        },
        {
          title: 'Obedience',
          speaker: 'Wera Hahn'
        },
        {
          title: 'Massage für den Hund',
          speaker: 'Birgit Gehbauer'
        },
        {
          title: 'Hundezucht & Genetik',
          speaker: 'Dr. Helga Eichelberg'
        },
        {
          title: 'Spaß mit Hund',
          speaker: 'Christina Sondermann'
        },
        {
          title: 'Feinheiten in der Belohnung',
          speaker: 'Viviane Theby'
        },
      ]
    },
    {
      year: 2011,
      examn: [
        {
          title: 'Erfolgreich mit Kunden kommunizieren',
          speaker: 'Prof. Dr. Heiko Girnth'
        },
        {
          title: 'Dummytraining',
          speaker: 'Tina Schnatz'
        },
        {
          title: 'Erste Hilfe für den Hund – Theorie und Praxis',
          speaker: 'Julia Fuchs'
        },
        {
          title: 'Die homöopathische Hausapotheke für den Hund',
          speaker: 'Elvira Franke'
        },
        {
          title: 'Duell auf offener Strasse',
          speaker: 'Nadin Matthews'
        },
        {
          title: 'Qualitäts – Siegel der pet-group GbR',
          speaker: 'Günther Bloch, Prof. Dr. Udo Gansloßer, Ulrike Thurau'
        },
        {
          title: 'Longier-Workshop',
          speaker: 'Biologin Anke Lehne'
        },
      ]
    },
    {
      year: 2010,
      examn: [
        {
          title: 'IX. Internationales Hundesymposium 2010 Animal Learn',
          speaker: 'Hyperaktivität bei Hunden - Maria Hense, „Guckst Du!“ - Eva Bodfäldt, Selbst zusammengestellte Futterrationen bei Hunden - Dr. Britta Dobenecker, Stress, Angst und Aggression - Anders Hallgren, Blutwerte verstehen - Dr. Michael Lehner, Die moderne Rassehundezucht auf Kosten unserer Hunde - Verena Buckel, Beurteilung gefährlicher Hunde - Anders Hallgren'
        },
        {
          title: 'BHV-Seminar „Zerren – Zappeln – Zähne zeigen“',
          speaker: 'Dr. Katja Frey'
        },
        {
          title: 'Antijagdtraining',
          speaker: 'Uwe Friedrich'
        },
        {
          title: 'Das Kontakthalten des Hundes fördern',
          speaker: 'Anton Fichtlmeier'
        },
        {
          title: 'Konfliktverhalten Aggression – Raufer',
          speaker: 'Ina & Thomas Baumann'
        },
        {
          title: 'Wölfisch für Hundehalter – von Alpha, Dominanz und anderen populären Irrtümern',
          speaker: 'Günther Bloch'
        },
        {
          title: 'Nasenarbeit für besondere Familienhunde',
          speaker: 'Alexandra Grunow'
        }
      ]
    },
    {
      year: 2009,
      examn: [
        {
          title: 'Baden Württemberg Wolfserwartungsland – Herzlich willkommen Wolf oder bleib wo Du bist',
          speaker: 'Vladimir Bologov'
        },
        {
          title: 'Faszination Wolf',
          speaker: 'Astrid & Rüdiger Szelest'
        },
        {
          title: 'BHV-Seminar „Schmerz, – Äußerung, – Erkennung, – Management“',
          speaker: 'Dr. Roderich Sondermann'
        },
        {
          title: 'BHV-Seminar „Geschlechtsapparat und hormonelle Erkrankungen des Hundes, der Verdauungstrakt, Ekto – und Endoparasiten“',
          speaker: 'Dr. Katja Hose'
        },
        {
          title: 'Kommunikation zwischen Mensch und Hund',
          speaker: 'Mirko Tomasini'
        },
        {
          title: 'Der Bewegungsapparat des Hundes, typische Krankheitsbilder und ihre Behandlungsmöglichkeiten',
          speaker: 'Maja Stiebing'
        },
        {
          title: 'BHV-Seminar „Problemverhalten III“',
          speaker: 'Dr. Esther Schalke & Dr. Barbara Schöning'
        }
      ]
    },
    {
      year: 2008,
      examn: [
        {
          title: '„Vom Sinn und Unsinn der Erziehungsstile aus verhaltensbiologischer Sicht“',
          speaker: 'Prof. Dr. Udo Gansloßer'
        },
        {
          title: 'BHV-Seminar „Lernverhalten II“',
          speaker: 'Dr. Ursula Breuer & Dr. Sabine Seufert'
        },
        {
          title: 'BHV-Seminar „Problemverhalten I“',
          speaker: 'Dr. Ursula Breuer & Christiane Wergowski'
        },
        {
          title: '„Hundeverhalten erkennen und verstehen“',
          speaker: 'Dr. Ursula Breuer'
        },
        {
          title: '„Lernen, Gedächtnis und Stress“',
          speaker: 'Prof. Dr. Udo Gansloßer'
        }
      ]
    },
    {
      year: 2007,
      examn: [
        {
          title: 'BHV-Seminar „Kommunikation mit dem Kunden“',
          speaker: 'Rainer Schröder & Dr. Christiane Wergowski'
        },
        {
          title: 'BHV-Seminar „Ethologie“',
          speaker: 'Sabine Winkler'
        },
        {
          title: 'BHV-Seminar „Lernverhalten I“',
          speaker: 'Dr. Ursula Breuer & Celina Del Amo'
        },
        {
          title: 'BHV-Seminar „Zucht, Haltung, Ernährung, Erste Hilfe“',
          speaker: 'Dr. Diane Hebeler, Dr. Jennifer Hirschfeld & Dr. Uta Wree'
        },
        {
          title: '„Treibball-Trainerinnen-Seminar“',
          speaker: 'Jan Nijboer'
        },
        {
          title: '„Treibball-Trainerinnen-Aufbauseminar“',
          speaker: 'Jan Nijboer'
        },
        {
          title: '„Mantrailing – Seminar“',
          speaker: 'Suchhundestaffel Remstal e.V.'
        }
      ]
    },
    {
      year: 2001,
      examn: [
        {
          title: 'Seminar „Herdenschutzhunde“',
          speaker: 'Thomas Schoke'
        },
        {
          title: 'Sachkundenachweis des deutschen Tierschutzbundes',
          speaker: 'Akademie für Tierschutz, München'
        }
      ]
    },
    {
      year: 1996,
      examn: [
        {
          title: 'Seminar „Hundeerziehung in Theorie und Praxis II“',
          speaker: 'Günther Bloch'
        },
        {
          title: 'Seminar „Hundeerziehung in Theorie und Praxis I“',
          speaker: 'Günther Bloch'
        },
        {
          title: '„TTouch-Seminar“',
          speaker: 'Linda Tellington-Jones'
        }
      ]
    }
  ];

  public dogs = [
    {
      name: 'Eowyn vom sonnigen Auenland',
      image:'../../../assets/images/Eowyn.jpg',
      breed: 'Berger de Picardie',
      description: 'Dummyspezialistin, Hauptberuflich süße Maus'
    },
    {
      name: 'Burgfräulein Bö von den Seelengefährten',
      image:'../../../assets/images/Boe.jpg',
      breed: 'Laekenois',
      description: 'Allrounderin, immer im Dienst'
    }
  ];
}
