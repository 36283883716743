<app-hero background="../../../../assets/images/index-splash.jpg"
    mobileBackground="../../../../assets/images/index-splash-mobile.jpg" position="object-center" [justify]>
    <ng-container overlay>
        <div class="flex flex-col p-8 sm:py-12 lg:pr-16 h-full justify-between sm:justify-center gap-8 sm:gap-12">
            <div>
                <div class="flex flex-col lg:flex-row lg:gap-2 sm:justify-end">
                    <h1 class="text-4xl sm:text-5xl font-semibold text-center sm:text-right">Langjährige</h1>
                    <h1 class="text-4xl sm:text-5xl font-semibold text-center sm:text-right">Erfahrung</h1>
                </div>
                <div class="flex flex-col lg:flex-row lg:gap-2 sm:justify-end">
                    <p class="text-3xl sm:text-4xl text-center sm:text-right font-thin">bildet die Basis</p>
                    <p class="text-3xl sm:text-4xl text-center sm:text-right font-thin">meines Wissens</p>
                </div>
            </div>
            <div class="hidden md:flex justify-end text-xl">
                <p class="w-2/3 lg:w-1/2 font-light text-right break-keep">
                    Erleben Sie, wie Ihr Hund gerne lernt und voller Vertrauen mit Ihnen zusammenarbeitet.
                </p>
            </div>

            <div class="w-full flex flex-col items-center sm:items-end gap-4">
                <a routerLink="/ueber-uns"><app-button [primary]="true">Mehr lesen</app-button></a>
                <a (click)="scrollContact($event)"><app-button [tertiary]="true">Kontakt</app-button></a>
            </div>
        </div>
    </ng-container>
</app-hero>

<!-- Notifications -->
<app-section [css]="(loadingEvents$ | async) == false ? 'bg-primary-50' : ''" *ngIf="(loadingNotifications$ | async) == false">
    <app-notification *ngFor="let notification of (notifications$ | async)" [description]="notification.description" [name]="notification.name"></app-notification>
</app-section>

<!-- Events -->
<app-section *ngIf="(loadingEvents$ | async) == false">
    <div class="flex flex-col gap-8 sm:gap-16">
        <h1 class="text-4xl text-center lg:text-6xl font-semibold break-keep">Anstehende Workshops & Seminare</h1>
        <div class="flex w-full flex-row flex-wrap justify-center gap-10">
            <!-- Card -->
            <app-card class="w-full sm:w-[calc(50%-1.25rem)] lg:w-[calc(33.333333%-1.666667rem)] xl:w-[calc(25%-1.875rem)] items-stretch"
                *ngFor="let event of (events$ | async)" [description]="event.description" [title]="event.name" [image]="event.picture" [mobileImage]="event.picture_mobile" [link]="'/events/' + event.id" [appointment]="event.appointment">
            </app-card>
            <a routerLink="/events" class="basis-full flex justify-center"><app-button [primary]="true">Mehr entdecken</app-button></a>
        </div>
    </div>
</app-section>

<!-- Courses -->
<app-section css="bg-primary-50">
    <div class="w-full flex items-center gap-10">
        <div class="w-1/2 hidden lg:flex scale-x-[–1]">
            <img src="../../../assets/images/Index-Kursangebote.jpg" class="object-contain"
                style="mask-image: url(../../../assets/images/Maske-Rotate.svg); mask-repeat: no-repeat; -webkit-mask-image: url(../../../assets/images/Maske-Rotate.svg); -webkit-mask-repeat: no-repeat;"
                placeholder="logo.png" alt="Ruby steht vor Tafel" />
        </div>
        <div class="w-full lg:w-1/2 flex flex-col">
            <h1 class="text-4xl lg:text-6xl text-center lg:text-right font-semibold">Kursangebote</h1>

            <p class="w-full py-6 sm:py-12 text-center lg:text-right font-light">
                Wir bieten Ihnen und Ihrem Hund ein umfangreiches Angebot unterschiedlicher Kurse.
                Ganz egal, ob Sie uns mit Ihrem Welpen besuchen wollen, oder ob Ihr Hund schon etwas älter geworden ist.
            </p>

            <div class="flex flex-wrap w-full justify-center lg:justify-end gap-8 gap-y-5">
                <a routerLink="/kurse"><app-button [primary]="true">Alle Kurse</app-button></a>
                <a routerLink="/stundenplan"><app-button>Zum Stundenplan</app-button></a>
            </div>
        </div>
    </div>
</app-section>

<app-section>
    <div>
        <div class="flex flex-col gap-8 sm:gap-16">
            <h1 class="text-4xl text-center lg:text-6xl font-semibold break-keep">Wir bieten mehr als nur Training</h1>
            <div class="flex w-full flex-wrap justify-center gap-10">
                <!-- Card -->
                <app-card
                    class="w-full sm:w-[calc(50%-1.25rem)] lg:w-[calc(33.333333%-1.666667rem)] xl:w-[calc(25%-1.875rem)] items-stretch"
                    [small]="true" description="Hallenboden ist toll." title="Indoor Training"
                    [image]="'../../../../assets/images/indoor.jpg'"
                    [mobileImage]="'../../../../assets/images/indoor-mobile.jpg'"></app-card>
                <app-card
                    class="w-full sm:w-[calc(50%-1.25rem)] lg:w-[calc(33.333333%-1.666667rem)] xl:w-[calc(25%-1.875rem)] items-stretch"
                    [small]="true" description="Mehrere Wiesen zum trainieren." title="Outdoor Training"
                    image="../../../../assets/images/outdoor.jpg"
                    mobileImage="../../../../assets/images/outdoor-mobile.jpg"></app-card>
                <!-- <app-card class="w-full sm:w-[calc(50%-1.25rem)] lg:w-[calc(33.333333%-1.666667rem)] items-stretch" [small]="true" description="Mehr als 26 Jahre Erfahrung mit allen Arten von Hunden." title="Lange Erfahrung" image="../../../../assets/logos/logo-text.png"></app-card> -->
                <!-- <app-card class="w-full sm:w-[calc(50%-1.25rem)] lg:w-[calc(33.333333%-1.666667rem)] items-stretch" [small]="true" description="All unsere Trainer sind geprüft und zertifiziert." title="Zertifizierte Trainer" image="../../../../assets/logos/logo-text.png"></app-card> -->
                <app-card
                    class="w-full sm:w-[calc(50%-1.25rem)] lg:w-[calc(33.333333%-1.666667rem)] xl:w-[calc(25%-1.875rem)] items-stretch"
                    [small]="true" description="Hauseigener Shop für den alltäglichen Bedarf." title="Shop"
                    image="../../../../assets/images/shop.jpg"
                    mobileImage="../../../../assets/images/shop-mobile.jpg"></app-card>
                <app-card
                    class="w-full sm:w-[calc(50%-1.25rem)] lg:w-[calc(33.333333%-1.666667rem)] xl:w-[calc(25%-1.875rem)] items-stretch"
                    [small]="true" description="Gerne kommen ich bei Bedarf auch zu Ihnen nach Hause." title="Hausbesuche"
                    image="../../../../assets/images/hausbesuch.jpg"
                    mobileImage="../../../../assets/images/hausbesuch-mobile.jpg"></app-card>
            </div>
        </div>
    </div>
</app-section>

<!-- News -->
<app-section css="bg-primary-50">
    <div class="flex flex-col gap-8 sm:gap-16">
        <h1 class="text-4xl text-center lg:text-6xl font-semibold">Neuigkeiten</h1>
        <div *ngIf="loadingNews$ | async" class="flex justify-center">
            <div class="scale-150">
                <app-loading></app-loading>
            </div>
        </div>
        <div class="flex w-full justify-center flex-wrap gap-10" *ngIf="(loadingNews$ | async) == false">
            <!-- Card -->
            <app-card
                class="w-full sm:w-[calc(50%-1.25rem)] lg:w-[calc(33.333333%-1.666667rem)] xl:w-[calc(25%-1.875rem)] items-stretch"
                *ngFor="let post of (news$ | async)" [description]="post.text" [title]="post.title" [image]="post.image"
                [mobileImage]="post.image_mobile" [link]="'/aktuelles/'+post.id"></app-card>
        </div>
    </div>
</app-section>