import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { IEvent } from 'src/app/types/event.interface';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.page.component.html',
  styleUrls: ['./event-details.page.component.css']
})
export class EventDetailsPageComponent implements OnInit, OnDestroy {

  private routerSubscription!: Subscription;
  public event?: IEvent;
  public bookable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly _dataService: DataService,
    private route: ActivatedRoute,
  ) { }

  public readonly loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  public async ngOnInit(): Promise<void> {
    this.routerSubscription = this.route.params.subscribe(async (params) => {
      // Dynamically load Data from the API
      const event = await this._dataService.fetchEvent(params['id']);
      this.event = event;
      if (event) {
        this.bookable$.next(new Date(event?.appointment?.toString()).getTime() > Date.now());
        this.loading$.next(false);
      }
    });
  }
  /**
* Ensure that the router subscription is unsubscribed from.
*/
  public async ngOnDestroy(): Promise<void> {
    if (this.routerSubscription) this.routerSubscription.unsubscribe();
  }
}
