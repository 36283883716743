import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css']
})
export class ButtonComponent {

  /**
   * Apply primary styles to the button.
   */
  @Input() primary?: boolean;

  /**
   * Apply tertiary styles to the button.
   */
  @Input() tertiary?: boolean;

  /**
   * Disable the button.
   */
  @Input() disabled?: boolean;

  /**
   * Limit the width of the button.
   */
  @Input() limited?: boolean;

  /**
   * Apply button styles to the button.
   */
  @Input() icon?: boolean;

  public primaryStyle = 'uppercase font-semibold py-2 rounded-full min-w-fit border border-primary-500 hover:bg-primary-400 active:bg-primary-300 disabled:opacity-25';
  public secondaryStyle = 'uppercase font-semibold bg-transparent py-2 rounded-full min-w-fit border border-primary-500 text-primary-500 hover:border-primary-400 hover:text-primary-400 active:border-primary-300 active:text-primary-300 disabled:opacity-25';
  constructor() {}
}
