<div class="flex flex-wrap sm:flex-nowrap gap-4 sm:gap-10 items-center justify-center">
    <div class="flex items-center w-32 h-32  sm:w-48 sm:h-48">
        <img [alt]="name" [src]="image" class="w-full object-cover rounded-full aspect-square object-top" />
    </div>
    <div class="w-full sm:w-[calc(50%-1.25rem)] text-center sm:text-left">
        <p class="font-semibold break-keep">{{name}}</p>
        <p class="italic">{{breed}}</p>
        <p class="italic">{{description}}</p>
    </div>
</div>
