<app-section>
    <h1 class="font-bold text-2xl py-4">Impressum</h1>
    <p class="font-semibold text-xl py-4">Angaben gemäß § 5 TMG</p>
    <p class="font-normalm text-md">
        Hundeschule Karin Schwarz<br />
        Mühlstraße 4<br />
        73614 Schorndorf<br /><br />
        IHK-Zertifizierung zur Hundetrainerin und Verhaltensberaterin<br />
        Vollmitglied im Berufsverband der Hundeerzieher und Verhaltensberater e. V.<br />
        Zertifiziert nach den Richtlinien der pet-group GbR<br />
        Sachkundenachweis nach §11 TschG<br /><br />
        Sitz des Unternehmens: Schorndorf, Deutschland<br />
        Steuernummer: 82338/39085 | Zuständiges Finanzamt: Finanzamt Schorndorf<br />
    </p>

    <p class="font-semibold text-xl py-4">Kontakt</p>
    <p class="font-normalm text-md">
        Telefon: 07181 473466<br />
        Mobil: 0160 8083992<br />
        E-Mail: karin.schwarz@hundeschule-karinschwarz.de<br />
    </p>

    <p class="font-semibold text-xl py-4">Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV</p>
    <p class="font-normalm text-md">
        Karin Schwarz<br />
        Mühlstraße 4<br />
        73614 Schorndorf<br />
        <a>Datenschutzerklärung</a>
    </p>

    <p class="font-semibold text-xl py-4">Haftung für Inhalte</p>
    <p class="font-normalm text-md">
        Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. <br />
        Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.<br />
        Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. <br />
        Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. <br />
        Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.<br />
    </p>

    <p class="font-semibold text-xl py-4">Haftung für Links</p>
    <p class="font-normalm text-md">
        Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. <br />
        Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. <br />
        Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. <br />
        Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. <br />
        Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.<br />
        Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. <br />
        Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.<br />
    </p>

    <p class="font-semibold text-xl py-4">Urheberrecht</p>
    <p class="font-normalm text-md">
        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. <br />
        Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. <br />
        Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.<br />
        Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. <br />
        Insbesondere werden Inhalte Dritter als solche gekennzeichnet. <br />
        Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. <br />
        Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.<br />
    </p>

    <p class="font-normalm text-md py-16">Quellenangaben: Bilder privat | Texte von Karin Schwarz</p>
</app-section>