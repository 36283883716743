<app-hero [background]="course?.splash_image" [mobileBackground]="course?.splash_image_mobile" [position]="'object-center'">
    <ng-container overlay>
        <div class="absolute left-4 top-4">
            <app-back-button />
        </div>
        <div class="flex flex-col p-8 sm:py-12 lg:pr-16 justify-center h-full">
            <div class="flex flex-col lg:flex-row lg:gap-2 justify-end">
                <h1 class="text-4xl sm:text-6xl font-semibold text-center sm:text-right">{{course?.title}}</h1>
            </div>

            <div class="flex justify-end">
                <p class="sm:w-1/2 py-6 sm:py-12 font-light text-center sm:text-right">{{course?.short_description}}</p>
            </div>

            <div class="w-full flex justify-center sm:justify-end">
                <a (click)="scrollAppointments($event)"><app-button [primary]="true">Termine</app-button></a>
            </div>
        </div>
    </ng-container>
</app-hero>


<app-section *ngIf="loadingCourseDetails$ | async" css="flex justify-center">
    <div class="scale-150">
        <app-loading></app-loading>
    </div>
</app-section>

<app-section *ngIf="(loadingCourseDetails$ | async) == false">
    <div class="w-full flex flex-wrap items-center gap-16">
        <div class="w-full lg:w-[calc(50%-2rem)]">
            <p class="text-base font-light sm:font-normal text-center lg:text-left">{{ course?.description  }}</p>
        </div>
        <div class="w-full lg:w-[calc(50%-2rem)]" *ngIf="course?.gallery_image">
            <img [src]="course?.gallery_image" class="object-contain" style="mask-image: url(../../../assets/images/Maske.svg); mask-repeat: no-repeat; mask-position: center; -webkit-mask-image: url(../../../assets/images/Maske.svg); -webkit-mask-repeat: no-repeat; -webkit-mask-position: center;" [alt]="course?.name"/>
        </div>
    </div>
</app-section>

<app-section css="bg-primary-50">
    <div class="flex flex-col gap-6" id="appointments">
        <h1 class="text-4xl lg:text-6xl font-semibold text-center">Termine</h1>
        <div class="flex flex-wrap w-full justify-center gap-10" *ngIf="(loadingAppointments$ | async) == false">
            <div *ngIf="(appointments$ | async)!.length == 0" class="w-full sm:w-1/2 flex flex-col items-center gap-4">
                <p class="font-light text-center">Termine nach Vereinbarung. Bei Interesse senden Sie mir gerne eine Anfrage oder kontaktieren mich telefonisch.</p>
                <a href="mailto:karin.schwarz@hundeschule-karinschwarz.de"><app-button>Kurs anfragen</app-button></a>
           </div>
           <div *ngIf="(appointments$ | async)!.length > 0" class="w-full sm:w-1/2 flex flex-col items-center gap-4">
                <p class="font-light text-center">Bei Interesse an diesem Kurs senden Sie mir gerne eine Anfrage oder kontaktieren mich telefonisch.</p>
                <a href="mailto:karin.schwarz@hundeschule-karinschwarz.de"><app-button>Kurs anfragen</app-button></a>
           </div>
        </div>
    </div>
    <div *ngIf="loadingAppointments$ | async" class="flex justify-center">
        <div class="scale-150">
            <app-loading></app-loading>
        </div>
    </div>
    
    <div class="flex flex-wrap w-full justify-center gap-10 pt-8 sm:pt-16" *ngIf="(appointments$ | async)!.length > 0">
        <app-appointment
            class="w-full sm:w-[calc(50%-1.25rem)] md:w-[calc(33.333333%-1.666667rem)] lg:w-[calc(25%-1.875rem)] items-stretch"
             *ngFor="let appts of (appointments$ | async)"
             [start]="appts.start" [end]="appts.ending" [trainerImage]="appts.trainer.image" [trainerName]="appts.trainer.name" [day]="appts.day"
            ></app-appointment> 
    </div>
</app-section>