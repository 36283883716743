import { Directive, HostListener } from '@angular/core';
import { NavigationService } from '../services/navigation.service';

@Directive({ selector: '[backNavigation]' })
export class BackNavigationDirective {

    constructor(private readonly _navigationService: NavigationService) { }

    @HostListener('click')
    public async onClick(): Promise<void> {
        await this._navigationService.back();
    }
}