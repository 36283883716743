<!-- 
    TODO: Add to all Card being event
    class="w-full lg:w-[calc(50%-1.25rem)] items-stretch"
 -->
<div *ngIf="event;" class="w-full h-full rounded-2xl flex flex-row even:flex-row-reverse justify-between bg-white shadow-around" [ngClass]="css+(link ? ' cursor-pointer' : '')" [routerLink]="link ?? link">
    <!-- <img [alt]="title" [src]="image" class="hidden sm:flex w-1/2 lg:w-2/5 object-cover rounded-2xl" /> -->
    <div class="hidden sm:flex w-1/2 lg:w-2/5">
        <app-image [alt]="title" [src]="image" [mobileSrc]="mobileImage" css="rounded-2xl w-full h-full object-cover"/>
    </div>
    <div class="w-full sm:w-1/2 lg:w-3/5 flex flex-col justify-between gap-6 items-center p-8 sm:p-10">
        <div class="flex flex-col gap-3 sm:gap-6">
            <div>
                <!-- TODO: Check date format for card with appointment -->
                <h1 *ngIf="appointment" class="text-xl sm:text-2xl text-center font-bold text-primary-500">{{(appointment | date: 'dd.MM.yyyy') ?? '{NO_APPOINTMENT}' }}</h1>
                <h1 class="text-xl sm:text-2xl font-light sm:font-normal text-center">{{ title ?? '{NO_TITLE}' }}</h1>
            </div>
            <p class="text-base font-thin text-center">{{ trim(description) ?? '{NO_DESCRIPTION}' }}</p>
        </div>
        <a *ngIf="link" [routerLink]="link" class="text-primary-500 hover:text-primary-300 font-bold uppercase underline underline-offset-8">Mehr lesen</a>
    </div>
</div>

<!-- 
    TODO: Add to all Cards not being event 
    class="w-full sm:w-[calc(50%-1.25rem)] lg:w-[calc(33.333333%-1.666667rem)] xl:w-[calc(25%-1.875rem)] items-stretch"
-->
<div *ngIf="!event" class="w-full h-full rounded-2xl flex flex-row sm:flex-col bg-white shadow-around" [ngClass]="css+(link ? ' cursor-pointer' : '')" [routerLink]="link ?? link">
    <!-- <img [alt]="title" [src]="image" class="w-2/5 sm:w-full object-cover rounded-2xl aspect-video" /> -->
    <div class="w-2/5 sm:w-full">
        <app-image [alt]="title" [src]="image" [mobileSrc]="mobileImage" css="rounded-2xl w-full h-full object-cover"/>
    </div>
    <div class="w-3/5 sm:w-full h-full flex flex-col gap-3 sm:gap-6 p-8 sm:p-10" [ngClass]="link ? 'justify-between' : 'justify-center'">
        <div class="flex flex-col gap-6">
            <div>
                <!-- TODO: Check date format for card with appointments -->
                <h1 *ngIf="appointment" class="text-xl sm:text-2xl font-bold text-center text-primary-500">{{ (appointment | date: 'dd.MM.yyyy') ?? '{NO_APPOINTMENT}' }}</h1>
                <h1 class="text-xl sm:text-2xl font-thin sm:font-normal text-center">{{ title ?? '{NO_TITLE}' }}</h1>
            </div>
            <p class="hidden sm:block font-thin text-center">{{ trim(description) ?? '{NO_DESCRIPTION}' }}</p>
        </div>
        <a *ngIf="link" [routerLink]="link" class="text-center text-primary-500 hover:text-primary-300 font-bold uppercase underline underline-offset-8">Mehr lesen</a>
    </div>
</div>