
<div *ngIf="(loading$ | async)" [ngClass]="css" class="bg-gray-300 justify-center items-center w-full h-full hidden md:flex" [style]="styleOverride">
    <app-loading></app-loading>
</div>

<div *ngIf="(mobileLoading$ | async)" [ngClass]="css" class="bg-gray-300 flex justify-center items-center w-full h-full md:hidden" [style]="styleOverride">
    <app-loading></app-loading>
</div>

<img *ngIf="(loading$ | async) === false" [src]="(image | async)!" [ngClass]="css" [alt]="alt" class="hidden md:block" [style]="styleOverride" [width]="width" [height]="height"/>
<img *ngIf="(mobileLoading$ | async) === false" [src]="(mobileImage | async)!" [ngClass]="css" [alt]="alt" class="block md:hidden" [style]="styleOverride" [width]="width" [height]="height"/>