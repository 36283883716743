import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, of, tap } from 'rxjs';
import { CacheService } from '../services/cache.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {

    /** Flag for enabling request logging and performance tracking of the cache interceptor */
    private readonly debug: boolean = !environment.production && localStorage.getItem('debug') === 'true';

    constructor(private readonly _cache: CacheService) {}

    /**
     * Incremental cache interceptor based on the url of the request.
     * Returns response from cache if available, otherwise forwards request to next handler and adds response to cache.
     * 
     * @why? Reduces network traffic and speeds up the application.
     * @param req HttpRequest from Angular
     * @param next next HttpHandler from Angular
     * @returns Response from cache or next
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const started = Date.now();

        // Skip non-GET requests
        if (req.method !== 'GET') { 
            this.log(`[Cache] [-] unsupported method: ${req.method}`); 
            return next.handle(req);
        }

        const key = req.urlWithParams;
        const cachedResponse = this._cache.get(key);

        const reload = req.headers.get('x-reload') === 'true';

        if (cachedResponse && !reload) {
            return of(cachedResponse).pipe(tap(_ => {
                const elapsed = Date.now() - started;
                this.log(`[Cache] [~] ${key} took ${elapsed} ms.`);
            }));
        }

        return next.handle(req).pipe(tap(event => {
            if (event instanceof HttpResponse) {
                this._cache.set(key, event);
                const elapsed = Date.now() - started;
                this.log(`[Cache] [+] ${key} took ${elapsed} ms.`);
            }
        }));
    }

    /**
     * logs a message to the console if debug is enabled
     * @param message Message to log
     */
    private log(message: string): void {
        if (this.debug) console.log(message);
    }
}