import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { firstValueFrom } from "rxjs/internal/firstValueFrom";
import { ICourse } from "../types/course.interface";
import { ICategory } from "../types/category.interface";
import { IEvent } from "../types/event.interface";
import { INews } from "../types/news.interface";
import { environment } from "src/environments/environment";
import { ITrainer } from "../types/trainer.interface";
import { ISimplifiedAppoinment, ITrainerAppointment } from "../types/appointment.interface";
import { IDay } from "../types/date.interface";
import { IDog } from "../types/dog.interface";
import { INotification } from "../types/notification.interface";

@Injectable({
    providedIn: "root"
})
export class DataService {

    private readonly apiUrl = environment.api || 'http://localhost:7071/api';

    constructor(private readonly _http: HttpClient) { }

    public categories$: BehaviorSubject<ICategory[]> = new BehaviorSubject<ICategory[]>([]);

    public courses$: BehaviorSubject<ICourse[]> = new BehaviorSubject<ICourse[]>([]);

    public events$: BehaviorSubject<IEvent[]> = new BehaviorSubject<IEvent[]>([]);

    public news$: BehaviorSubject<INews[]> = new BehaviorSubject<INews[]>([]);

    public trainers$: BehaviorSubject<ITrainer[]> = new BehaviorSubject<ITrainer[]>([]);

    public dogs$: BehaviorSubject<IDog[]> = new BehaviorSubject<IDog[]>([]);

    public simplifiedAppointments$: BehaviorSubject<ISimplifiedAppoinment[]> = new BehaviorSubject<ISimplifiedAppoinment[]>([]);

    public days$: BehaviorSubject<IDay[]> = new BehaviorSubject<IDay[]>([]);

    public notifications$: BehaviorSubject<INotification[]> = new BehaviorSubject<INotification[]>([]);

    /**
     * Fetch courses from the API and update the courses$ BehaviorSubject
     * @param reload Set this to true to bypass the cache and fetch from API
     */
    public async fetchCourses(reload: boolean = false) {
        const courses = await firstValueFrom(this._http.get<ICourse[]>(`${this.apiUrl}/courses`, {headers: {'x-reload': reload.toString()}}));
        this.courses$.next(courses);
    }

    /**
     * Try load a course from courses$ or fetch from API if not loaded yet
     * @param name name of the course to fetch
     */
    public async fetchCourse(name: string) {
        if(!name) return;
        if(this.courses$.getValue().length > 0) return this.courses$.getValue().find(c => c.name === name);
        const course = firstValueFrom(await this._http.get<ICourse>(`${this.apiUrl}/courses/${name}`));
        return course;
    }
    
    /**
     * Try load all appointments for course or fetch from API if not loaded yet
     * @param name name of the course to fetch appointments
     * @param id id of the course to fetch appointments
     */
    public async fetchAppointments(id?: number) {
        const appts = firstValueFrom(await this._http.get<ITrainerAppointment[]>(`${this.apiUrl}/appointments/${id}`));
        return appts;
    }
    
    /**
     * Fetch categories from the API and update the categories$ BehaviorSubject
     */
    public async fetchCategories() {
        const categories = await firstValueFrom(this._http.get<ICategory[]>(`${this.apiUrl}/categories`));
        this.categories$.next(categories);
    }

    /**
     * Fetch events from the API and update the events$ BehaviorSubject
     * Events are sorted by appointment (newest first - oldest last)
     */
    public async fetchEvents(reload: boolean = false) {
        const events = await firstValueFrom(this._http.get<IEvent[]>(`${this.apiUrl}/events`, {headers: {'x-reload': reload.toString()}}));
        const dateEvents = events.map(e => {
            return {...e,appointment:new Date(e.appointment)};
        }).sort((a,b) => b.appointment.getTime() - a.appointment.getTime());
        this.events$.next(dateEvents);
    }

    /**
     * Try load a event from events$ or fetch from API if not loaded yet
     * @param id id of the event to fetch
     */
    public async fetchEvent(id: number) {
        if(!id) return;
        if(this.events$.getValue().length > 0) return this.events$?.getValue().find(e => e.id == id);
        const event = firstValueFrom(await this._http.get<IEvent>(`${this.apiUrl}/events/${id}`));
        return event;
    }

    /**
     * Fetch news from the API and update the news$ BehaviorSubject
     * News are sorted by creation-date (newest first - oldest last)
     */
    public async fetchNews(reload: boolean = false) {
        const news = await firstValueFrom(this._http.get<INews[]>(`${this.apiUrl}/news`, {headers: {'x-reload': reload.toString()}}));
        const dateNews = news.map(n => {
            return {...n,created_at:new Date(n.created_at)};
        }).sort((a,b) => b.created_at.getTime() - a.created_at.getTime());
        this.news$.next(news);
    }

     /**
     * Try load a single news from news$ or fetch from API if not loaded yet
     * @param id id of the single news to fetch
     */
     public async fetchSingleNews(id: number) {
        if(!id) return;
        if(this.news$.getValue().length > 0) return this.news$?.getValue().find(n => n.id == id);
        const news = firstValueFrom(await this._http.get<INews>(`${this.apiUrl}/news/${id}`));
        return news;
    }

    /**
     * Fetch trainers from the API and update the trainers$ BehaviorSubject
     */
    public async fetchTrainers(reload: boolean = false) {
        const trainers = await firstValueFrom(this._http.get<ITrainer[]>(`${this.apiUrl}/trainers`, {headers: {'x-reload': reload.toString()}}));
        this.trainers$.next(trainers);
    }

    /**
     * Try load a trainer from trainers$ or fetch from API if not loaded yet
     * @param id id of the trainer to fetch
     */
    public async fetchTrainer(uid: string) {
        if(!uid) return;
        if(this.trainers$.getValue().length > 0) return this.trainers$.getValue().find(t => t.uid == uid);
        const trainer = firstValueFrom(await this._http.get<ITrainer>(`${this.apiUrl}/trainers/${uid}`));
        return trainer;
    }

    /**
     * Fetch all dogs  from the API and update the dogs$ BehaviorSubject
     */
    public async fetchDogs(reload: boolean = false) {
        const dogs = await firstValueFrom(this._http.get<IDog[]>(`${this.apiUrl}/dogs`,  {headers: {'x-reload': reload.toString()}}));
        this.dogs$.next(dogs);
    }

    /**
     * Try load all dogs of a trainer from dogs$ or fetch from API if not loaded yet
     * @param uid uid of the trainer to fetch dogs for
     */
    public async fetchDogsByTrainer(uid: string) {
        if(!uid) return;
        if(this.dogs$.getValue().length > 0) return this.dogs$?.getValue().filter(d => d.trainer_uid == uid);
        const dogs = firstValueFrom(await this._http.get<IDog[]>(`${this.apiUrl}/trainers/${uid}/dogs`));
        return dogs;
    }

    /**
     * Fetch appointments for schedule from the API and update the simplifiedAppointments$ BehaviorSubject
     */
    public async fetchSimplifiedAppointments(reload: boolean = false) {
        const appointments = await firstValueFrom(this._http.get<ISimplifiedAppoinment[]>(`${this.apiUrl}/appointments`, {headers: {'x-reload': reload.toString()}}));
        const timed = appointments.map(a => {
                return {
                    ...a,
                    start: (a.start).slice(0,5),
                    ending: (a.ending).slice(0,5)
                }
            });
        this.simplifiedAppointments$.next(timed);
    }

     /**
     * Fetch days from the API and update the days$ BehaviorSubject
     */
     public async fetchDays() {
        const days = await firstValueFrom(this._http.get<IDay[]>(`${this.apiUrl}/days`));
        this.days$.next(days);
    }

    public async fetchNotifications(reload: boolean = false){
        const notifications = await firstValueFrom(this._http.get<INotification[]>(`${this.apiUrl}/notifications`,  {headers: {'x-reload': reload.toString()}}));
        this.notifications$.next(notifications);
    }
}