import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filter'
})
export class FilterPipe implements PipeTransform {

    /**
     * Filter an array using a filter function and a filter value
     * @param value Array to filter
     * @param args tuple containing the filter function and the filter value
     * @returns filtered array
     * @example
     * ```ts
     *  filterFunction = (courses: ICourse[], categoryId: number) => {
            return courses.filter(c => c.category_id === categoryId);
        };
     * ```

        ```html
        <div *ngFor="let category of categories$ | async">
          <div *ngFor="let course of courses$ | async | filter: (filterFunction, category.id)">
          {{course.name}}
         </div>
         ```
     */
    transform(value: any[] | null, ...args: any[]): any {
        return args[0](value, args[1]);
    }
}