
<div [ngSwitch]="icon">
    <img *ngSwitchCase="'Hamburger'" src="../../../assets/icons/hamburger.svg" alt="Menu" />
    <img *ngSwitchCase="'Phone'" src="../../../assets/icons/phone.svg" alt="Phone" />
    <img *ngSwitchCase="'Close'" src="../../../assets/icons/close.svg" alt="Close" />
    <img *ngSwitchCase="'Location'" src="../../../assets/icons/location.svg" alt="Location" />
    <img *ngSwitchCase="'Down'" src="../../../assets/icons/down.svg" alt="Arrow down">
    <img *ngSwitchCase="'Up'" src="../../../assets/icons/up.svg" alt="Arrow up">
    <img *ngSwitchCase="'Delete'" src="../../../assets/icons/delete.svg" alt="Delete">
    <img *ngSwitchCase="'Edit'" src="../../../assets/icons/edit.svg" alt="Edit">
    <img *ngSwitchCase="'Plus'" src="../../../assets/icons/plus.svg" alt="Plus">
    <img *ngSwitchCase="'Minus'" src="../../../assets/icons/minus.svg" alt="Minus">
    <img *ngSwitchCase="'Info'" src="../../../assets/icons/info.svg" alt="Info"><img>

    <svg *ngSwitchCase="'Mail'" width="32" height="32" viewBox="0 0 40 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M30 0H10C4 0 0 3 0 10V24C0 31 4 34 10 34H30C36 34 40 31 40 24V10C40 3 36 0 30 0ZM30.94 12.18L24.68 17.18C23.36 18.24 21.68 18.76 20 18.76C18.32 18.76 16.62 18.24 15.32 17.18L9.06 12.18C8.75401 11.926 8.55814 11.5635 8.5134 11.1683C8.46867 10.7731 8.57853 10.376 8.82 10.06C9.34 9.42 10.28 9.3 10.92 9.82L17.18 14.82C18.7 16.04 21.28 16.04 22.8 14.82L29.06 9.82C29.7 9.3 30.66 9.4 31.16 10.06C31.68 10.7 31.58 11.66 30.94 12.18Z" fill="#F9B315"/>
    </svg>

    <svg *ngSwitchCase="'Facebook'" width="32" height="32" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M40 28.38C40 35.66 35.66 40 28.38 40H26C24.9 40 24 39.1 24 38V26.46C24 25.92 24.44 25.46 24.98 25.46L28.5 25.4C28.78 25.38 29.02 25.18 29.08 24.9L29.78 21.08C29.7936 20.9934 29.7882 20.9049 29.7643 20.8206C29.7404 20.7363 29.6985 20.6582 29.6414 20.5916C29.5844 20.5251 29.5136 20.4717 29.4339 20.4352C29.3543 20.3986 29.2676 20.3798 29.18 20.38L24.92 20.44C24.36 20.44 23.92 20 23.9 19.46L23.82 14.56C23.82 14.24 24.08 13.96 24.42 13.96L29.22 13.88C29.56 13.88 29.82 13.62 29.82 13.28L29.74 8.48C29.74 8.14 29.48 7.88 29.14 7.88L23.74 7.96C22.9517 7.97197 22.1736 8.13939 21.4501 8.45265C20.7266 8.7659 20.0721 9.21885 19.524 9.78552C18.9759 10.3522 18.545 11.0215 18.2561 11.755C17.9671 12.4884 17.8257 13.2718 17.84 14.06L17.94 19.56C17.96 20.12 17.52 20.56 16.96 20.58L14.56 20.62C14.22 20.62 13.96 20.88 13.96 21.22L14.02 25.02C14.02 25.36 14.28 25.62 14.62 25.62L17.02 25.58C17.58 25.58 18.02 26.02 18.04 26.56L18.22 37.96C18.24 39.08 17.34 40 16.22 40H11.62C4.34 40 0 35.66 0 28.36V11.62C0 4.34 4.34 0 11.62 0H28.38C35.66 0 40 4.34 40 11.62V28.38Z" fill="#F9B315"/>
    </svg>
    
    <svg *ngSwitchCase="'Instagram'" width="32" height="32" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M28.38 0H11.62C4.34 0 0 4.34 0 11.62V28.36C0 35.66 4.34 40 11.62 40H28.36C35.64 40 39.98 35.66 39.98 28.38V11.62C40 4.34 35.66 0 28.38 0ZM20 27.76C15.72 27.76 12.24 24.28 12.24 20C12.24 15.72 15.72 12.24 20 12.24C24.28 12.24 27.76 15.72 27.76 20C27.76 24.28 24.28 27.76 20 27.76ZM31.84 9.76C31.74 10 31.6 10.22 31.42 10.42C31.22 10.6 31 10.74 30.76 10.84C30.396 10.9945 29.9943 11.0371 29.606 10.9623C29.2177 10.8874 28.8605 10.6987 28.58 10.42C28.4 10.22 28.26 10 28.16 9.76C28.0573 9.51963 28.0029 9.26138 28 9C28 8.74 28.06 8.48 28.16 8.24C28.26 7.98 28.4 7.78 28.58 7.58C29.04 7.12 29.74 6.9 30.38 7.04C30.52 7.06 30.64 7.1 30.76 7.16C30.88 7.2 31 7.26 31.12 7.34C31.22 7.4 31.32 7.5 31.42 7.58C31.6 7.78 31.74 7.98 31.84 8.24C31.94 8.48 32 8.74 32 9C32 9.26 31.94 9.52 31.84 9.76Z" fill="#F9B315"/>
    </svg>
</div>