import { Modify } from "./helpers";

export enum TrainerRole {
    TRAINER_M = 'Trainer',
    TRAINER_W = "Trainerin",
    TRAINER = "Trainer:in",
    TRAINER_AND_OWNER = "Inhaberin & Trainerin"
}

export interface ITrainer {
    id?: number;
    uid?: string;
    name: string;
    role: TrainerRole | string;
    created_at: Date;
    avatar?: string;
    splash_image?: string;
    description?: string;
}

export interface ITrainerModel extends Modify<ITrainer, {avatar: File, splash_image: File}> {}