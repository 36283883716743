<app-section css="bg-primary-50 relative">
    <div class="absolute left-4 top-4">
        <app-back-button />
    </div>
    <div class="flex-wrap-reverse sm:flex-nowrap w-full flex items-center gap-10">
        <div class="w-full sm:w-1/2 flex flex-col">
            <h1 class="text-4xl lg:text-6xl text-center sm:text-left font-semibold">Karin Schwarz</h1>

            <p class="w-full pt-6 lg:pt-12 text-center sm:text-left font-light">
                Seit 1995 bin ich in der Hundeausbildung, im Tierschutz und als Tierheimmitarbeiterin tätig.
                Besonders am Herzen liegt mir die Arbeit mit verhaltensauffälligen Hunden und Tierschutzhunden, die besondere Aufmerksamkeit und Hilfe zur Integration in ihre (neuen) Familien benötigen. 
            </p>
            <p class="hidden lg:block pt-6 text-left font-light">
                Wie schwer dieser Weg mitunter sein kann, weiß ich durch mehr als 25 Jahre Zusammenleben mit sogenannten verhaltensauffälligen Hunden. 
                Darüber hinaus sehe ich meinen Schwerpunkt in der Arbeit mit Welpen und Junghunden und ihren Familien, damit beide Seiten zu einem harmonischen Team zusammenwachsen. 
                Um Ihnen und Ihrem Tier dieses zu ermöglichen, biete ich Ihnen viel Herz und Hundeverstand gepaart mit Wissen und Erfahrung.
            </p>
        </div>
        <div class="w-full sm:w-1/2 justify-end">
            <img src="../../../assets/images/Karin.jpg" class="object-contain"
                style="mask-image: url(../../../assets/images/Maske.svg); mask-repeat: no-repeat; -webkit-mask-image: url(../../../assets/images/Maske.svg); -webkit-mask-repeat: no-repeat;"
                placeholder="logo.png" alt="Ruby steht vor Tafel" />
        </div>
    </div>
    <p class="lg:hidden pt-6 text-center sm:text-left font-light">
        Wie schwer dieser Weg mitunter sein kann, weiß ich durch mehr als 25 Jahre Zusammenleben mit sogenannten verhaltensauffälligen Hunden. 
        Darüber hinaus sehe ich meinen Schwerpunkt in der Arbeit mit Welpen und Junghunden und ihren Familien, damit beide Seiten zu einem harmonischen Team zusammenwachsen. 
        Um Ihnen und Ihrem Tier dieses zu ermöglichen, biete ich Ihnen viel Herz und Hundeverstand gepaart mit Wissen und Erfahrung.
    </p>
</app-section>
<app-section css="flex flex-col w-full items-center gap-12">
    <div class="flex flex-col gap-8 sm:gap-16">
        <h1 class="text-4xl text-center lg:text-6xl font-semibold">Meine Mädels</h1>
        <div class="flex flex-wrap gap-20 sm:gap-10 justify-center">
            <div class="xl:w-[calc(50%-1.25rem)]" *ngFor="let dog of dogs">
                <app-dog [name]="dog.name" [description]="dog.description" [image]="dog.image" [breed]="dog.breed"></app-dog>
            </div>
        </div>
    </div>
</app-section>

<app-section css="bg-primary-100">
    <div class="flex flex-col gap-8 sm:gap-16">
        <h1 class="text-4xl text-center lg:text-6xl font-semibold">Fortbildungen</h1>
        <div class="flex flex-wrap gap-10 gap-y-0">
            <div class="flex w-full sm:w-[calc(50%-1.25rem)]" *ngFor="let examn of examns">
                <app-accordion class="w-full" [year]="examn.year" [educationExams]="examn.examn" />
            </div>
        </div>
    </div>
</app-section>