import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent {

  @Input() name?: string;

  @Input() description?: string;

  @Input() start?: Date;

  @Input() end?: Date;

}
