<app-hero background="../../../../assets/images/events-splash.jpg" mobileBackground="../../../../assets/images/events-splash-mobile.jpg" [position]="'object-top'">
    <ng-container overlay>
        <div class="flex flex-col p-8 sm:py-12 lg:pr-16 justify-center h-full">
            <div class="flex flex-col lg:flex-row lg:gap-2 justify-end">
                <h1 class="text-4xl sm:text-6xl font-semibold text-center sm:text-right">Workshops & </h1>
                <h1 class="text-4xl sm:text-6xl font-semibold text-center sm:text-right">Seminare</h1>
            </div>

            <div class="flex justify-end">
                <p class="sm:w-3/5 py-6 sm:py-12 font-light text-center sm:text-right">
                    Hier finden Sie spannende Workshops, informative Seminare und erfahrene Referent:innen Rund um das Thema Hund.
                </p>
            </div>
        </div>
    </ng-container>    
</app-hero>

<app-section *ngIf="(loadingUpcoming$ | async) && (loadingPast$ | async) " css="flex justify-center">
    <div class="scale-150">
        <app-loading></app-loading>
    </div>
</app-section>

<!-- Events -->
<app-section *ngIf="(loadingUpcoming$ | async) == false">
    <div class="flex flex-col gap-8 sm:gap-16">
        <h1 class="text-4xl lg:text-6xl font-semibold">Das kommt</h1>
        <div class="flex flex-wrap w-full gap-10">
            <app-card class="w-full sm:w-[calc(50%-1.25rem)] lg:w-[calc(33.333333%-1.666667rem)] xl:w-[calc(25%-1.875rem)] items-stretch" *ngFor="let post of (upcomingEvents$ | async)" [title]="post.name" [description]="post.description" [link]="'/events/'+post.id" [image]="post.picture" [mobileImage]="post.picture_mobile" [appointment]="post.appointment" />
        </div>
    </div>
</app-section>

<app-section *ngIf="(loadingPast$ | async) == false" [css]="(loadingUpcoming$ | async) == false ? 'bg-primary-50' : ''">
    <div class="flex flex-col gap-8 sm:gap-16">
        <h1 class="text-4xl lg:text-6xl font-semibold">Das war schon</h1>
        <div class="flex flex-wrap w-full gap-10">
            <app-card class="w-full sm:w-[calc(50%-1.25rem)] lg:w-[calc(33.333333%-1.666667rem)] xl:w-[calc(25%-1.875rem)] items-stretch" *ngFor="let post of (pastEvents$ | async)" [title]="post.name" [description]="post.description" [link]="'/events/'+post.id" [image]="post.picture" [mobileImage]="post.picture_mobile" [appointment]="post.appointment" />
        </div>
    </div>
</app-section>

