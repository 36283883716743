<app-hero background="../../../assets/images/kurse-splash.jpg" mobileBackground="../../../assets/images/kurse-splash-mobile.jpg" [position]="'object-center'">
    <ng-container overlay>
        <div class="flex flex-col p-8 sm:py-12 lg:pr-16 justify-center h-full">
            <div class="flex flex-col lg:flex-row lg:gap-2 justify-end">
                <h1 class="text-4xl sm:text-6xl font-semibold text-center sm:text-right">Kursangebot</h1>
            </div>

            <div class="flex justify-end">
                <p class="sm:w-1/2 py-6 sm:py-12 font-light text-center sm:text-right">
                    Wir bieten Ihnen und Ihrem Hund ein umfangreiches Angebot unterschiedlicher Kurse.
                    Ganz egal, ob Sie uns mit Ihrem Welpen besuchen wollen, oder ob Ihr Hund schon etwas älter geworden ist.
                </p>
            </div>

            <div class="w-full flex justify-center sm:justify-end">
                <a routerLink="/stundenplan"><app-button [primary]="true">Zum Stundenplan</app-button></a>
            </div>
        </div>
    </ng-container>    
</app-hero>

<app-section *ngIf="loading$ | async" css="flex justify-center">
    <div class="scale-150">
        <app-loading></app-loading>
    </div>
</app-section>

<app-section *ngFor="let category of categories$ | async; let odd = odd;" [css]="odd ? 'bg-primary-50' :''">
    <div class="flex flex-col gap-8 sm:gap-16">
        <h1 class="text-4xl text-center lg:text-6xl font-semibold">{{ category.name }}</h1>
        <div class="flex w-full flex-wrap justify-center gap-10">
            <app-card class="w-full sm:w-[calc(50%-1.25rem)] lg:w-[calc(33.333333%-1.666667rem)] xl:w-[calc(25%-1.875rem)] items-stretch" *ngFor="let course of ((courses$ | async) | filter : filterFunction : category.id )" [description]="course.short_description" [title]="course.title" [image]="course.splash_image" [mobileImage]="course.splash_image_mobile" [link]="course.name" [threshold]="-1"/>
        </div>
    </div>
</app-section>

