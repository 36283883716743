import { Component, OnInit } from '@angular/core';
import { IsActiveMatchOptions } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { debounceTime, fromEvent, map } from 'rxjs';
import { navigationLinks } from 'src/app/config/navigation-links';
import { DataService } from 'src/app/services/data.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { ICourse } from 'src/app/types/course.interface';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {

  public links = navigationLinks;
  public year = new Date().getFullYear();
  public opened = false;
  public linkStyle: IsActiveMatchOptions = { matrixParams: 'ignored', queryParams: 'ignored', paths: 'subset', fragment: 'exact' };

  public scrollEvent$ = fromEvent(window, 'scroll');
  public topOfPage$ = this.scrollEvent$.pipe(debounceTime(10), map(_ => window.scrollY < 100));

  constructor(
    public auth: AuthService,
    public _navigationService: NavigationService,
    public _dataService: DataService,
  ) { }

  public readonly categories$ = this._dataService.categories$;
  public readonly courses$ = this._dataService.courses$;

  public scrollTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  /**
   * filter courses by category id used in the template
   * @param courses all courses
   * @param categoryId category id to filter by
   */
  public filterFunction = (courses: ICourse[], categoryId: number) => {
    const filteredCourses = courses.filter(c => c.category_id === categoryId);
    let chunckedArray = []
    while(filteredCourses.length){
      chunckedArray.push(filteredCourses.splice(0,3));
    }
    return chunckedArray;
  };

  public async ngOnInit(): Promise<void> {
    await this._dataService.fetchCategories();
    await this._dataService.fetchCourses();
  }
}
