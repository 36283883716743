import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.css']
})
export class HeroComponent {

  /**
   * url to the background image
   */
  @Input() background?: string;

  /**
   * url to the mobile-background image
   */
  @Input() mobileBackground?: string;

  /**
   * disable the gradient effect
   */
  @Input() effectDisabled?: boolean;

  /**
   * position of the background image
   */
  @Input() position?: "object-bottom" | "object-center" | "object-top";

  /**
   * justify the content
   */
  @Input() justify?: boolean; 

  public effects:string = "bg-white bg-opacity-70 sm:bg-transparent sm:bg-gradient-to-l sm:from-white sm:to-transparent";

  constructor() {}
}
