export const navigationLinks = [
  {
    link: '/home',
    name: 'Home',
  },
  {
    link: '/kurse',
    name: 'Kurse',
  },
  {
    link: '/stundenplan',
    name: 'Stundenplan',
  },
  {
    link: '/events',
    name: 'Workshops & Seminare',
  },
  {
    link: '/aktuelles',
    name: 'Aktuelles',
  },
  {
    link: '/ueber-uns',
    name: 'Über uns',
  },
];
