<app-hero [background]="event?.picture" [mobileBackground]="event?.picture_mobile" [position]="'object-center'">
    <ng-container overlay>
        <div class="absolute left-4 top-4">
            <app-back-button />
        </div>
        <div class="flex flex-col p-8 sm:py-12 lg:pr-16 justify-center h-full gap-2 sm:gap-6">
            <div class="flex flex-col lg:flex-row lg:gap-2 justify-end">
                <h1 class="text-4xl sm:text-6xl font-semibold text-center sm:text-right">{{event?.name}}</h1>
            </div>

            <div class="flex justify-center sm:justify-end">
                <p class="pb-6 sm:pb-12 font-light text-center sm:text-right text-2xl sm:text-4xl">{{event?.appointment | date: 'dd.MM.yyyy HH:mm' : '-0' }} Uhr</p>
            </div>

            <div  *ngIf="(bookable$ | async)" class="w-full flex justify-center sm:justify-end">
                <a href="mailto:karin.schwarz@hundeschule-karinschwarz.de"><app-button [primary]="true">Anfragen</app-button></a>
            </div>
        </div>
    </ng-container>
</app-hero>

<app-section *ngIf="loading$ | async" css="flex justify-center">
    <div class="scale-150">
        <app-loading></app-loading>
    </div>
</app-section>

<app-section *ngIf="(loading$ |async) == false">
    <div class="w-full flex flex-wrap justify-center gap-16">
            <p class="w-full md:w-1/2 text-base font-light sm:font-normal text-center">{{ event?.description  }}</p>
    </div>
</app-section>