import { Component, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';
import { ICourse } from 'src/app/types/course.interface';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.page.component.html',
  styleUrls: ['./courses.page.component.css']
})
export class CoursesPageComponent implements OnInit {

  constructor(private readonly _dataService: DataService) { }

  public readonly categories$ = this._dataService.categories$;
  public readonly courses$ = this._dataService.courses$;

  public readonly loading$ = combineLatest([this._dataService.categories$, this._dataService.courses$]).pipe(
    map(([categories, courses]) => {
      return categories.length === 0 && courses.length === 0;
    })
  );

  /**
   * filter courses by category id used in the template
   * @param courses all courses
   * @param categoryId category id to filter by
   */
  public filterFunction = (courses: ICourse[], categoryId: number) => {
    return courses.filter(c => c.category_id === categoryId);
  };

  public async ngOnInit(): Promise<void> {
    await this._dataService.fetchCategories();
    await this._dataService.fetchCourses();
  }

}
