import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.page.component.html',
  styleUrls: ['./events.page.component.css']
})
export class EventsPageComponent implements OnInit {

  constructor(private readonly _dataService: DataService) { }

  public readonly pastEvents$ = this._dataService.events$.pipe(map(events => {
    return events.filter(e => (e.appointment as Date).getTime() < Date.now());
  }));

  public readonly upcomingEvents$ = this._dataService.events$.pipe(map(events => {
    return events.filter(e => (e.appointment as Date).getTime() >= Date.now());
  }));

  public readonly loadingPast$ = (this.pastEvents$).pipe(
    map((events) => {
      return events.length === 0;
    })
  );

  public readonly loadingUpcoming$ = (this.upcomingEvents$).pipe(
    map((events) => {
      return events.length === 0;
    })
  );

  public async ngOnInit(): Promise<void> {
    await this._dataService.fetchEvents();
  }
}
