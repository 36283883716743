<!-- 
    TODO: Add to all Trainers
    class="w-full sm:w-[calc(50%-1.25rem)] lg:w-[calc(33.333333%-1.666667rem)] xl:w-[calc(25%-1.875rem)] items-stretch"
-->
<div class="w-full h-full rounded-2xl flex flex-row sm:flex-col bg-white shadow-around items-center p-4 sm:p-10 gap-6 sm:gap-10" [ngClass]="css">
    <img [alt]="name" [src]="image" class="w-1/3 sm:w-3/4 object-cover rounded-full aspect-square" />
    <div class="w-full h-full flex flex-col justify-center gap-3 sm:gap-6">
        <div class="flex flex-col">
            <h1 class="text-xl sm:text-2xl font-thin sm:font-normal text-center">{{ name || '{NO_TITLE}' }}</h1>
            <p class="font-thin text-center">{{ _role || '{NO_DESCRIPTION}' }}</p>
        </div>
        <a *ngIf="link" [routerLink]="link" class="text-center text-primary-500 hover:text-primary-300 font-bold uppercase underline underline-offset-8">Mehr erfahren</a>
    </div>
</div>