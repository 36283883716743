<app-hero [background]="news?.image" [mobileBackground]="news?.image_mobile" [position]="'object-center'">
    <ng-container overlay>
        <div class="absolute left-4 top-4">
            <app-back-button />
        </div>
        <div class="flex flex-col p-8 sm:py-12 lg:pr-16 justify-center h-full gap-2 sm:gap-6">
            <div class="flex flex-col lg:flex-row lg:gap-2 justify-end">
                <h1 class="text-4xl sm:text-6xl font-semibold text-center sm:text-right">{{news?.title}}</h1>
            </div>
        </div>
    </ng-container>
</app-hero>

<app-section *ngIf="loading$ | async" css="flex justify-center">
    <div class="scale-150">
        <app-loading></app-loading>
    </div>
</app-section>

<app-section *ngIf="(loading$ | async) == false">
    <div class="w-full flex flex-wrap justify-center gap-16">
        <p class="w-full md:w-1/2 text-base font-light sm:font-normal text-center">{{ news?.text  }}</p>
    </div>
</app-section>