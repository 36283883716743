import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { INews } from 'src/app/types/news.interface';

@Component({
  selector: 'app-news-details',
  templateUrl: './news-details.page.component.html',
  styleUrls: ['./news-details.page.component.css']
})
export class NewsDetailsPageComponent implements OnInit, OnDestroy {

  private routerSubscription!: Subscription;
  public news?: INews;

  constructor(
    private readonly _dataService: DataService,
    private route: ActivatedRoute,
  ) { }

  public readonly loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  ngOnInit(): void {
    this.routerSubscription = this.route.params.subscribe(async (params) => {
      // Dynamically load Data from the API
      const news = await this._dataService.fetchSingleNews(params['id']);
      this.news = news;
      if(news) this.loading$.next(false);
    });
  }

  /**
  * Ensure that the router subscription is unsubscribed from.
  */
  public async ngOnDestroy(): Promise<void> {
    if (this.routerSubscription) this.routerSubscription.unsubscribe();
  }
}