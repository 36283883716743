import { Inject, Injectable } from "@angular/core";
import { NavigationEnd, NavigationStart, Router } from "@angular/router";

@Injectable({
    providedIn: "root"
})
export class NavigationService {

    private _history: string[] = [];

    private _backNavigationFlag = false;

    private _scrollPosition: [x: number, y: number] = [0, 0];

    constructor(private readonly _router: Router) {
        this._router.events.subscribe((event) => {
            if(event instanceof NavigationStart) {
                if(!this._backNavigationFlag) this._scrollPosition = [window.scrollX, window.scrollY];
            }

            if (event instanceof NavigationEnd) {
                if (!this._backNavigationFlag) {
                    this._history.push(event.urlAfterRedirects);
                    window.scrollTo(0, 0);
                } else {
                    setTimeout(() => {
                        window.scrollTo(this._scrollPosition[0], this._scrollPosition[1]);
                    }, 100);
                }

                this._backNavigationFlag = false;
            }
        });
    }

    public async back(): Promise<boolean> {
        const previousUrl = this._history?.pop() ?? "/";
        const prevPreviousUrl = this._history?.at(-1) ?? "/";
        this._backNavigationFlag = true;
        const fragments = this.fragmentUrl(previousUrl);
        if (fragments.at(-1) === prevPreviousUrl?.slice(1)) {
            return await this._router.navigate([fragments.join('/')]);
        }
        return await this._router.navigate([prevPreviousUrl]);
    }
    public fragmentUrl(url: string): string[] {
        const fragments = url.split('/').filter(x => x !== "");
        fragments.pop();
        return fragments;
    }
}