import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AuthModule, AuthHttpInterceptor, AuthConfig } from '@auth0/auth0-angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './components/app/app.component';
import { AuthButtonComponent } from './components/auth-button/auth-button.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { IndexPageComponent } from './pages/index/index.page.component';
import { HeroComponent } from './components/hero/hero.component';
import { AboutPageComponent } from './pages/about/about.page.component';
import { NewsPageComponent } from './pages/news/news.page.component';
import { EventsPageComponent } from './pages/events/events.page.component';
import { CoursesPageComponent } from './pages/courses/courses.page.component';
import { StundenplanPageComponent } from './pages/stundenplan/stundenplan.page.component';
import { SharedModule } from './shared/shared.module';
import { environment } from 'src/environments/environment';
import { CourseDetailPageComponent } from './pages/course-detail/course-detail.page.component';
import { EventDetailsPageComponent } from './pages/event-details/event-details.page.component';
import { NewsDetailsPageComponent } from './pages/news-details/news-details.page.component';
import { ImprintPageComponent } from './pages/imprint/imprint.page.component';
import { PrivacyPageComponent } from './pages/privacy/privacy.page.component';
import { CacheInterceptor } from './interceptors/cache.interceptor';
import { KarinDetailsPageComponent } from './pages/karin-details/karin-details.page.component';
import { AccordionComponent } from './components/accordion/accordion.component';
import { TrainerDetailsPageComponent } from './pages/trainer-details/trainer-details.page.component';

@NgModule({
  declarations: [
    AppComponent,

    // Components
    AuthButtonComponent,
    HeroComponent,

    // Pages
    IndexPageComponent,
     AboutPageComponent,
     NewsPageComponent,
     EventsPageComponent,
     CoursesPageComponent,
     CourseDetailPageComponent,
     StundenplanPageComponent,
     EventDetailsPageComponent,
     NewsDetailsPageComponent,
     ImprintPageComponent,
     PrivacyPageComponent,
     KarinDetailsPageComponent,
     AccordionComponent,
     TrainerDetailsPageComponent,
  ],
  imports: [
    BrowserModule,
    SharedModule,
    HttpClientModule,
    AuthModule.forRoot({
      ...(environment.auth as AuthConfig),
      authorizationParams: {
        ...environment.auth.authorizationParams,
        redirect_uri: window.location.origin,
        prompt: 'login'
      }
    }),

    AppRoutingModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
