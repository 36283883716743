import { Component, Input } from '@angular/core';
import { SupportedIcon } from 'src/app/types/supported-icons';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.css']
})
export class IconComponent {

  @Input() public icon: SupportedIcon = 'Close';
}
