import { HttpEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class CacheService {

    /** Cache Map for the http-interceptor */
    private cache: Map<string, HttpEvent<HttpResponse<any>>> = new Map<string, HttpEvent<HttpResponse<any>>>();
    
    constructor() { }
    
    /**
     * Searches the cache for a response with the given url
     * 
     * @param key Url of the request with parameters
     * @returns The HttpResponse from the cache or undefined if not available
     */
    public get(key: string): HttpEvent<HttpResponse<any>> | undefined {
        return this.cache.get(key);
    }

    /**
     * Caches the response from the request with the given url
     * Only response is needed for caching to prevent resending the request on cache hit
     * 
     * @param key Url of the request with parameters
     * @param value Response from the request
     */
    public set(key: string, value: HttpEvent<HttpResponse<any>>): void {
        this.cache.set(key, value);
    }
}