import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.page.component.html',
  styleUrls: ['./news.page.component.css']
})
export class NewsPageComponent implements OnInit {
  constructor(private readonly _dataService: DataService) { }

  public readonly news$ = this._dataService.news$;

  public readonly loading$ = (this.news$).pipe(
    map((news) => {
      return news.length === 0;
    })
  );

  public async ngOnInit(): Promise<void> {
    await this._dataService.fetchNews();
  }
}