import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { ISimplifiedAppoinment } from "../../types/appointment.interface";

@Component({
  selector: 'app-stundenplan',
  templateUrl: './stundenplan.page.component.html',
  styleUrls: ['./stundenplan.page.component.css']
})
export class StundenplanPageComponent implements OnInit {

  constructor(private readonly _dataService: DataService) { }

  public readonly backgroundColors = " bg-primary-100 sm:bg-primary-50 "

  public readonly appointments$ = this._dataService.simplifiedAppointments$;

  public readonly days$ = this._dataService.simplifiedAppointments$.pipe(map(appts => new Set (appts.map(a => a.day))));

  public readonly loading$ = (this.appointments$).pipe(
    map((appts) => {
      return appts.length === 0;
    })
  );


  /**
   * filter appointments by day used in the template
   * @param appointments all appointments
   * @param dayDay day-day to filter by
   */
  public filterFunction = (appointments: ISimplifiedAppoinment[], dayDay: string) => {
    return appointments.filter(a => a.day === dayDay);
  };

  public async ngOnInit(): Promise<void> {
    await this._dataService.fetchSimplifiedAppointments();
  }

}
