import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconComponent } from './components/icon/icon.component';
import { ButtonComponent } from './components/button/button.component';
import { CardComponent } from './components/card/card.component';
import { SectionComponent } from './components/section/section.component';
import { AppointmentComponent } from './components/appointment/appointment.component';
import { LoadingComponent } from './components/loading/loading.component';
import { RouterModule } from '@angular/router';
import { TrainerComponent } from './components/trainer/trainer.component';
import { ImageComponent } from './components/image/image.component';
import { FilterPipe } from '../pipes/filter.pipe';
import { BackNavigationDirective } from '../directives/back-navigation.directive';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { DogComponent } from './components/dog/dog.component';
import { NotificationComponent } from './components/notification/notification.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';

const components = [
  // Components
  IconComponent,
  ButtonComponent,
  CardComponent,
  SectionComponent,
  AppointmentComponent,
  TrainerComponent,
  LoadingComponent,
  ImageComponent,
  BackButtonComponent,
  DogComponent,
  NotificationComponent,
  TooltipComponent,

  // Pipes
  FilterPipe,

  // Directives
  BackNavigationDirective
];

@NgModule({
  declarations: [...components],
  imports: [CommonModule, RouterModule],
  exports: components,
})
export class SharedModule {}
