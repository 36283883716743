// =======================
// Development Environment
// =======================
export const environment = {
  production: false,
  api: 'https://hks-dev-api.azurewebsites.net/api',
  apiVersion: 'v1',
  auth: {
    domain: 'hks.eu.auth0.com',
    clientId: 'kDAln47lBoievC0dWxMUPaScjPk78f8f',
    authorizationParams: {
      audience: 'https://hks.lonely-sky.de/api',
    },
    httpInterceptor: {
      allowedList: ['https://hks-dev-api.azurewebsites.net/api/adm/*'],
    },
    cacheLocation: 'localstorage',
    useRefreshTokens: true,
  },
};
