import { Component, Input, OnInit } from '@angular/core';
import { ITrainer } from './../../../types/trainer.interface';
import { IDay } from './../../../types/date.interface';

@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.css']
})
export class AppointmentComponent implements OnInit {

  /**
   * Trainer-Image from the trainer of the appointment
   */
  @Input() trainerImage?: string;

  /**
   * Trainer-Namw from the trainer of the appointment
   */
  @Input() trainerName?: string;

  /**
   * Weekday of the appointment
   */
  @Input() day?: string;

  /**
   * Time when appointment starts
   */
  @Input() start?: Date | string;

  /**
   * Time when appointment ends
   */
  @Input() end?: Date | string;

  /**
   * If start and end are displayed
   * Default is just date
   */
  @Input() time?: boolean;

  /**
   * Title of the appointment shown below the image
   */
  @Input() title?: string;

  /**
   * Link for the "Zum Kurs" button
   */
  @Input() link?: string;

  /**
   * URL of the image
   */
  @Input() image?: string;

  @Input() mobileImage?: string;

  public linkURL?: string;

  ngOnInit(): void {
    if (this.link) this.linkURL = `/kurse/${this.link}`;
  }

}