import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-index-page',
  templateUrl: './index.page.component.html',
  styleUrls: ['./index.page.component.css']
})
export class IndexPageComponent implements OnInit {

  constructor(private readonly _dataService: DataService) { }

  /**
   * The next upcoming 4 Events
   */
  public readonly events$ = this._dataService.events$.pipe(map(events => {
    return events.filter(e => (e.appointment as Date).getTime() > Date.now()).slice(-4);
  }));

  /**
   * The most current 4 entries of news
   */
  public readonly news$ = this._dataService.news$.pipe(map(news => news.slice(0,4)));

  public readonly loadingEvents$ = (this.events$).pipe(
    map((events) => {
      return events.length === 0;
    })
  );

  public readonly loadingNews$ = (this.news$).pipe(
    map((news) => {
      return news.length === 0;
    })
  );

  /**
   * The most current Notification, not passed yet
   */
  public readonly notifications$ = this._dataService.notifications$.pipe(map(notifications => {
    return  notifications.filter(n =>(((new Date(n.start!.toString())).getTime()<= Date.now()) && ((new Date(n.end!.toString())).getTime() >= Date.now()))).slice(0,1);
  }));

  public readonly loadingNotifications$ = (this.notifications$).pipe(
    map((notifications) => {
      return notifications.length === 0;
    })
  );
  

  public async ngOnInit(): Promise<void> {
    await this._dataService.fetchEvents();
    await this._dataService.fetchNews();
    await this._dataService.fetchNotifications();
  }

  public scrollContact(e: Event) {
    document.getElementById("contact")?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    })
  }
}
