<div class="flex items-center justify-cente">
    <div class="left-32 cursor-pointe group">
        <app-icon [icon]="'Info'"></app-icon>
        <div class="relative">
            <div class="opacity-0 absolute bottom-0 inline-block w-64 px-4 py-3 mb-6 -ml-32 text-white bg-black rounded-lg transition-opacity duration-300 ease-out group-hover:opacity-100">
                <span class="inline-block text-sm">
                    {{tooltipText}}
                </span>
                <div class="absolute top-full w-0 h-0 left-1/2 border-l-8 border-r-8 border-t-8 border-l-transparent border-r-transparent border-t-black">
                </div>
            </div>
        </div>
    </div>
</div>