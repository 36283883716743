<app-hero background="../../../assets/images/schedule-splash.jpg" mobileBackground="../../../assets/images/schedule-splash-mobile.jpg">
    <ng-container overlay>
        <div class="flex flex-col p-8 sm:py-12 lg:pr-16 justify-center h-full">
            <div class="flex flex-col lg:flex-row lg:gap-2 justify-end">
                <h1 class="text-4xl sm:text-6xl font-semibold text-center sm:text-right">Stundenplan</h1>
            </div>

            <div class="flex justify-end">
                <p class="sm:w-1/2 py-6 sm:py-12 font-light text-center sm:text-right">
                    Hier finden Sie alle regelmäßigen Kurse in einem Überblick. 
                    Termine für Verhaltenstraining, Jagdkontrolltraining und Sonderkurse werden individuell nach Rücksprache vereinbart.
                </p>
            </div>

            <div class="w-full flex justify-center sm:justify-end">
                <a routerLink="/kurse"><app-button [primary]="true">Zu Kursen</app-button></a>
            </div>
        </div>
    </ng-container>
</app-hero>

<app-section *ngIf="loading$ | async" css="flex justify-center">
    <div class="scale-150">
        <app-loading></app-loading>
    </div>
</app-section>       
        
<app-section *ngFor="let day of (days$ | async); let odd = odd;" [css]="odd ? backgroundColors :''">
    <div class="flex flex-col gap-8 sm:gap-16">
        <h1 class="text-4xl text-center lg:text-6xl font-semibold">{{ day }}</h1>
        <div class="flex flex-wrap w-full justify-center gap-10">
            <app-appointment class="w-full sm:w-[calc(50%-1.25rem)] md:w-[calc(33.333333%-1.666667rem)] lg:w-[calc(25%-1.875rem)] items-stretch" [time]="true" *ngFor="let appts of (appointments$ | async) | filter : filterFunction : day" [title]="appts.course.title" [link]="appts.course.name" [start]="appts.start" [end]="appts.ending" [image]="appts.course.image" [mobileImage]="appts.course.mobileImage"></app-appointment> 
        </div>
    </div>
</app-section>