import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BehaviorSubject, map, of } from 'rxjs';
import { ImageService } from 'src/app/services/image.service';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.css']
})
export class ImageComponent implements OnInit, OnChanges {

  @Input()
  public src?: string | Blob;

  @Input()
  public mobileSrc?: string | Blob;

  @Input()
  public alt?: string;

  @Input()
  public css: string = '';

  @Input()
  public styleOverride: string = '';

  @Input()
  public width?: string;
  
  @Input()
  public height?: string;

  public image: BehaviorSubject<string> = new BehaviorSubject('');
  public mobileImage: BehaviorSubject<string> = new BehaviorSubject('');

  public loading$ = this.image.pipe(map((path) => path === ''));
  public mobileLoading$ = this.mobileImage.pipe(map((path) => path === ''));

  constructor(private _imageService: ImageService) {}
  
  async ngOnInit(): Promise<void> {
    if(this.mobileSrc === undefined) this.mobileSrc = this.src;
    await [this.loadImage(this.image, this.src), this.loadImage(this.mobileImage, this.mobileSrc)];
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if(!changes["src"]?.firstChange && changes["src"]?.currentValue) await this.loadImage(this.image, (changes["src"].currentValue));
    if(!changes["mobileSrc"]?.firstChange && changes["mobileSrc"]?.currentValue) await this.loadImage(this.mobileImage, (changes["mobileSrc"].currentValue));
  }

  /**
   * Loads an image from the given source and emits the path to the given subject
   * If the source is a blob or starts with blob: or data: the source is emitted directly
   * If the source starts with ../ the image is emitted directly
   * If the source starts with http:// or https:// the image is downloaded and emitted
   * If the device is mobile and has enough speed the original image is downloaded
   * If the device is mobile and has not enough speed the compressed image is downloaded
   * If the device is desktop the compressed image is downloaded
   * @param subject Subject to emit the image path to
   * @param source path or blob to load image from
   * @returns void
   */
  private async loadImage(subject: BehaviorSubject<string>, source?: string | Blob): Promise<void> {
    // TODO: Benjamin: Get's called twice for every course on the kurse-seite. Also geht's called twice for hero-image (once splash once mobile)
    if(source instanceof Blob) {
      subject.next(URL.createObjectURL(source));
      return;
    }

    if(source?.startsWith('blob:') || source?.startsWith('data:') || source?.startsWith('../')) {
      subject.next(source);
      return;
    }

    if(source?.startsWith('http://') || source?.startsWith('https://')) {
      const isMobileDevice = this._imageService.isMobileDevice();
      const hasEnoughSpeed = this._imageService.hasEnoughSpeed();

      // On Mobile device with enough speed download the original image
      if(isMobileDevice && hasEnoughSpeed) {
        const path = await this._imageService.download(source);
        subject.next(path);
        return;
      }

      // On Mobile device with not enough speed download the compressed image
      if(isMobileDevice && !hasEnoughSpeed) {
        const path = await this._imageService.downloadCompressedImage(source, '4');
        subject.next(path.file);
        return;
      }

      // On Desktop device
      if(!isMobileDevice) {
        const fastets = await this._imageService.downloadCompressedImage(source, '8');
        subject.next(fastets.file);
        if (!fastets.compressed) return;

        const fast = await this._imageService.downloadCompressedImage(source, '6');
        subject.next(fast.file);

        const avg = await this._imageService.downloadCompressedImage(source, '4');
        subject.next(avg.file);

        const high = await this._imageService.downloadCompressedImage(source, '2');
        subject.next(high.file);

        const best = await this._imageService.downloadCompressedImage(source, '1');
        subject.next(best.file);
        return;
      }
    }

    subject.next('');
  }

  

}
