    <app-hero background="../../../../assets/images/about-splash.jpg" mobileBackground="../../../../assets/images/about-splash-mobile.jpg" [effectDisabled]="true" position="object-top">
        <ng-container overlay>
            <div class="w-full flex justify-center py-8 items-start">
                <h1 class="flex flex-wrap gap-1 sm:gap-2 text-4xl justify-center text-center">Hundeschule<span class="font-bold">Karin&nbsp;Schwarz</span></h1>
            </div>
        </ng-container>
    </app-hero>

    <app-section css="flex flex-col w-full bg-primary-50 items-center gap-12">
        <h2 class="text-2xl sm:text-4xl sm:w-1/2 text-center">Langjährige Erfahrung bildet die Basis meines Wissens</h2>
        <p class="font-light sm:font-normal text-base sm:w-1/2 text-center">
            Ich sehe meine Aufgabe darin, Ihre Wünsche und Ziele in Einklang mit dem Wohl Ihres Hundes zu bringen. 
            Basierend auf den neuesten Erkenntnissen der Lerntheorie vermittelt meine Ausbildung Ihrem Hund viel Freude durch positive Verstärkung mit Futter, Lob und Spiel.
            Das bedeutet nicht, dass der Hund tun und lassen kann was er will, sondern feste Regeln und das Setzen von Grenzen gehören zur Hundeerziehung. 
            Dadurch werden Sie und Ihr Hund ein gutes Team, basierend auf gegenseitigem Vertrauen, Respekt und Verständnis für den Partner.
        </p>
        <p class="text-xl font-thin sm:w-1/2 text-center">Erleben Sie, wie Ihr Hund gerne lernt und voller Vertrauen mit Ihnen zusammenarbeitet.</p>
    </app-section>

    <app-section css="flex flex-wrap lg:flex-nowrap flex-col-reverse sm:flex-row gap-10 items-stretch">
        <div class="lg:w-1/3">
            <div class="bg-primary-50 p-8 sm:p-16 rounded-xl flex flex-col items-center gap-10">
                <h2 class="text-4xl">Professionelles Training für alle</h2>
                <p class="text-base">
                    Ich biete Ihnen und Ihrem Hund ein umfangreiches Angebot unterschiedlicher Kurse. 
                    Ganz egal, ob Sie uns mit Ihrem Welpen besuchen wollen, oder ob Ihr Hund schon etwas älter geworden ist.
                    Dabei spielt es keine Rolle, ob Sie mit einem kleinen Chihuahua oder beispielsweise mit einem großen Bernhardiner zu uns kommen. 
                    Meine Kurse sind für Hundebesitzer:innen in jedem Alter geeignet.
                </p>
            </div>
        </div>
        <div class="lg:w-2/3 flex flex-wrap sm:p-8 gap-6 sm:gap-12 justify-center">
            <div class="w-[calc(50%-1.5rem)] flex flex-col items-center gap-4" *ngFor="let stat of stats">
                <div class="text-center">
                    <p class="font-bold text-4xl sm:text-6xl text-primary-500">{{ stat.number }}</p>
                    <p class="font-thin text-xl sm:text-2xl">{{ stat.title }}</p>
                </div>
                <div class="text-center">
                    <p class="text-xs sm:text-sm font-thin px-2">{{ stat.text }}</p>
                    <p *ngIf="stat.subtext" class="text-xs sm:text-sm font-thin px-2">{{ stat.subtext }}</p>
                </div>
            </div>
        </div>
    </app-section>

    <app-section *ngIf="(loading$ | async)" css="flex justify-center bg-primary-50">
        <div class="scale-150">
            <app-loading></app-loading>
        </div>
    </app-section>

    <app-section css="bg-primary-50" *ngIf="(loading$ | async) == false">
        <!-- <div class="p-16">
            <h1 class="text-4xl text-center lg:text-6xl font-semibold">Zu Mir</h1>
        </div> -->
        
        <div class="flex w-full gap-10 justify-center flex-wrap">
            <app-trainer class="w-full sm:w-[calc(50%-1.25rem)] lg:w-[calc(33.333333%-1.666667rem)] xl:w-[calc(25%-1.875rem)] items-stretch" *ngFor="let trainer of trainers$ | async | filter : filterFunction : 'Karin Schwarz'" [image]="trainer.avatar ?? '/images/course-splash-color.png'" [name]="trainer.name" [role]="trainer.role" [link]="'karin'" />
        </div>
        <div class="p-16">
            <h1 class="text-4xl text-center lg:text-6xl font-semibold">Mein Team</h1>
        </div>
        
        <div class="flex w-full gap-10 justify-center flex-wrap">
            <app-trainer class="w-full sm:w-[calc(50%-1.25rem)] lg:w-[calc(33.333333%-1.666667rem)] xl:w-[calc(25%-1.875rem)] items-stretch" *ngFor="let trainer of (trainers$ | async) | filter : negativFilterFunction : 'Karin Schwarz'" [image]="trainer.avatar ?? '/images/course-splash-color.png'" [name]="trainer.name" [role]="trainer.role" [link]="trainer.uid"/>
        </div>
    </app-section>
