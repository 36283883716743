<button class="accordion p-4 border-t border-primary flex justify-between items-center" (click)="opened = !opened">{{year || 'Jahr'}}<app-icon [icon]="opened ? 'Minus' : 'Plus'"></app-icon></button>
<div *ngIf="opened" class="p-8 pt-0">
    <ul class="list-disc">
        <li *ngFor="let examn of educationExams">
            <div>
                <p class="font-semibold">{{examn.title || 'Fortbildungstitel'}}</p>
                <p>{{examn.speaker || 'Fortbildungsreferent'}}</p>
            </div>
        </li>
    </ul>
</div>
