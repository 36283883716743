import { Component, Input } from '@angular/core';
import { ISimplifiedEducation } from 'src/app/types/education.interface';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.css']
})
export class AccordionComponent {

  public opened: boolean = false;

  @Input() year?: number;

  @Input() educationExams?: ISimplifiedEducation[];

}
