<div *ngIf="background;" class="relative background">
  <app-image alt="Hero background image" [src]="background" [mobileSrc]="mobileBackground" [css]="'absolute top-0 bottom-0 left-0 right-0 z-0 h-full w-full object-cover '+position"/>
  <div class="absolute top-0 left-0 right-0 bottom-0 z-10" [ngClass]="effectDisabled ? 'bg-white bg-opacity-40' : effects">
      <div class="relative h-full flex items-center sm:justify-end" [ngClass]="justify ? 'justify-end' : 'justify-center'">
          <ng-content select="[overlay]"></ng-content>
      </div>
  </div>
</div>
<div *ngIf="!background" class="background bg-gradient-to-b from-white via-primary-200 to-primary-500 flex flex-col sm:flex-row-reverse">
  <ng-content select="[content]"></ng-content>
</div>