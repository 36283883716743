import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subscription, map } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { ITrainerAppointment } from 'src/app/types/appointment.interface';
import { ICourse } from 'src/app/types/course.interface';

@Component({
  selector: 'app-course-detail',
  templateUrl: './course-detail.page.component.html',
  styleUrls: ['./course-detail.page.component.css']
})
export class CourseDetailPageComponent implements OnInit, OnDestroy {

  private routerSubscription!: Subscription;
  public course?: ICourse;
  public appointments$: BehaviorSubject<ITrainerAppointment[]> = new BehaviorSubject<ITrainerAppointment[]>([]);

  constructor(
    private readonly _dataService: DataService, 
    private route: ActivatedRoute,
    ) {}

  public readonly loadingCourseDetails$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public readonly loadingAppointments$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  public async ngOnInit(): Promise<void> {
    this.routerSubscription = this.route.params.subscribe(async(params) => {
      // Dynamically load Data from the API
      const course = await this._dataService.fetchCourse(params['name']);
      this.course = course;
      if(course) this.loadingCourseDetails$.next(false);

      const appointments = await this._dataService.fetchAppointments(course?.id);
      this.appointments$.next(appointments);
      if(appointments) this.loadingAppointments$.next(false);
    });
  }

  public scrollAppointments(e: Event) {
    document.getElementById("appointments")?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    })
  }

  /**
   * Ensure that the router subscription is unsubscribed from.
   */
  public async ngOnDestroy(): Promise<void> {
    if(this.routerSubscription) this.routerSubscription.unsubscribe();
  }
}