import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ImageService {

  constructor(private readonly _http: HttpClient) {}

  /**
   * downloads an image from the given path
   * Use this method for cachable images
   * @param path url to download image from
   * @returns object url of downloaded image
   */
  public async downloadImage(path: string): Promise<{file: string; compressed: boolean}> {
    const filePath = this.removeExtension(path);
    const resizePath = this.appendResize(filePath);
    
    try {
      const fastest = await this.download(`${resizePath}_8.webp`);
      return {file: fastest, compressed: true};

    } catch(error) {
      const orginal = await this.download(path);
      return {file: orginal, compressed: false};
    }
  }

  public async downloadCompressedImage(path: string, quality: "1" | "2" | "4" | "6" | "8"): Promise<{file: string, compressed: boolean}> {
    const filePath = this.removeExtension(path);
    const resizePath = this.appendResize(filePath);

    try {
      const fastest = await this.download(`${resizePath}_${quality}.webp`);
      return {file: fastest, compressed: true};

    } catch(error) {
      const orginal = await this.download(path);
      return {file: orginal, compressed: false};
    }
  }

  public download(path: string): Promise<string> {
    return firstValueFrom(
      this._http.get(path, { responseType: 'blob' }).pipe(
        map((blob) => {
          if (!blob) return '';
          return URL.createObjectURL(blob);
        })
      )
    );
  }

  public downloadBlob(path: string): Promise<Blob | null> {
    return firstValueFrom(
      this._http.get(path, { responseType: 'blob' }).pipe(
        map((blob) => {
          if (!blob) return null;
          return blob;
        })
      )
    );
  }

  /**
   * Use feature detection to check if device is mobile
   * This is not 100% accurate but should be good enough
   * Detection is based on the maxTouchPoints property of the navigator object
   * @see https://developer.mozilla.org/en-US/docs/Web/API/Navigator/maxTouchPoints
   * @returns true if device is most likely a mobile device
   */
  public isMobileDevice(): boolean {
    return window.navigator?.maxTouchPoints > 0
  }

  /**
   * Use feature detection to check if device has enough speed to load high quality images
   * Detection is based on the downlink and saveData properties of the navigator.connection object
   * Experimental API, not supported by all browsers
   * @see https://developer.mozilla.org/en-US/docs/Web/API/NetworkInformation/downlink
   * @returns true if device has enough speed to load high quality images
   */
  public hasEnoughSpeed(): boolean {
    if(window.navigator && !(window.navigator as any).connection) return true;
    
    const connection = (window.navigator as Navigator & {connection: any}).connection;
    return connection.downlink >= 1 && !connection.saveData;
  }


  private removeExtension(path: string): string {
    return path.substring(0, path.lastIndexOf('.'));
  }

  /**
   * Add `/resize/*` to the path to get the resized image from the Azure Blob Storage
   * @param path apth containing the filename and container 
   * @returns path with `/resize/*` appended
   */
  private appendResize(path: string): string {
    const resize = path.split('/');
      const filename = resize.pop();
      resize.push('resize');
      resize.push(filename!);
      return resize.join('/');
  }
}
