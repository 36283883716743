import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { ITrainer } from 'src/app/types/trainer.interface';

@Component({
  selector: 'app-about',
  templateUrl: './about.page.component.html',
  styleUrls: ['./about.page.component.css'],
})
export class AboutPageComponent implements OnInit {
  constructor(private readonly _dataService: DataService) {}

  public readonly trainers$ = this._dataService.trainers$;

  public readonly loading$ = this.trainers$.pipe(
    map((trainer) => {
      return trainer.length === 0;
    })
  );

  public stats = [
    {
      number: '27',
      title: 'Jahre',
      text: 'Trainingserfahrung habe ich gesammelt und kann auf umfangreiches Wissen zurückgreifen, um Sie und Ihren Hund zu einem harmonischen Team werden zu lassen.',
    },
    {
      number: '82',
      title: 'Fortbildungen',
      text: 'Regelmäßig besuche ich Fort- und Weiterbildungen, um mein Wissen stetig zu vertiefen und auf dem neusten wissenschaftlichen Erkenntnisstand zu sein.',
      subtext: 'Meine Kompetenz für Sie und Ihren Hund!',
    },
    {
      number: '24',
      title: 'Kurse',
      text: 'Finden an 7 Tagen regelmäßig jede Woche statt. Darüber hinaus biete ich Verhaltensberatung, individuelle Einzelstunden und Jagdkontrolltraining für Sie und Ihren Vierbeiner an.',
    },
  ];

  public async ngOnInit(): Promise<void> {
    await this._dataService.fetchTrainers();
  }

  public filterFunction = (trainers: ITrainer[], trainerName: string) => {
    return trainers.filter((t) => t.name === trainerName);
  };

  public negativFilterFunction = (trainers: ITrainer[], trainerName: string) => {
    return trainers.filter((t) => t.name !== trainerName);
  };
}
