import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { IndexPageComponent } from './pages/index/index.page.component';
import { CoursesPageComponent } from './pages/courses/courses.page.component';
import { EventsPageComponent } from './pages/events/events.page.component';
import { NewsPageComponent } from './pages/news/news.page.component';
import { AboutPageComponent } from './pages/about/about.page.component';
import { StundenplanPageComponent } from './pages/stundenplan/stundenplan.page.component';
import { CourseDetailPageComponent } from './pages/course-detail/course-detail.page.component';
import { EventDetailsPageComponent } from './pages/event-details/event-details.page.component';
import { ImprintPageComponent } from './pages/imprint/imprint.page.component';
import { PrivacyPageComponent } from './pages/privacy/privacy.page.component';
import { NewsDetailsPageComponent } from './pages/news-details/news-details.page.component';
import { KarinDetailsPageComponent } from './pages/karin-details/karin-details.page.component';
import { TrainerDetailsPageComponent } from './pages/trainer-details/trainer-details.page.component';

const routes: Routes = [
  { path: 'admin', canActivate: [AuthGuard], loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
  { path: 'kurse', component: CoursesPageComponent, pathMatch: 'full'},
  { path: 'kurse/:name', component: CourseDetailPageComponent, pathMatch: 'full'},
  { path: 'stundenplan', component: StundenplanPageComponent, pathMatch: 'full'},
  { path: 'events', component: EventsPageComponent, pathMatch: 'full'},
  { path: 'events/:id', component: EventDetailsPageComponent, pathMatch: 'full'},
  { path: 'aktuelles', component: NewsPageComponent, pathMatch: 'full'},
  { path: 'aktuelles/:id', component: NewsDetailsPageComponent, pathMatch: 'full'},
  { path: 'ueber-uns', component: AboutPageComponent, pathMatch: 'full'},
  { path: 'home', component: IndexPageComponent, pathMatch: 'full' },
  { path: '', redirectTo: 'home' , pathMatch: 'full' },
  { path: 'impressum', component: ImprintPageComponent, pathMatch: 'full' },
  { path: 'privacy', component: PrivacyPageComponent, pathMatch: 'full' },
  { path: 'ueber-uns/karin', component: KarinDetailsPageComponent, pathMatch: 'full'},
  { path: 'ueber-uns/:uid', component: TrainerDetailsPageComponent, pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
