import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dog',
  templateUrl: './dog.component.html',
  styleUrls: ['./dog.component.css']
})
export class DogComponent {

  @Input() name?: string;

  @Input() image?: string;

  @Input() description?: string;

  @Input() breed?: string;
}
