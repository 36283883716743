import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent {

  /**
   * Additional classes for the card
   */
  @Input() css?: string;

  /**
   * URL of the image
   */
  @Input() image?: string;

  /**
   * URL of the mobile-image
   */
  @Input() mobileImage?: string;

  /**
   * Title of the card shown below the image
   */
  @Input() title?: string;

  /**
   * Description of the card shown below the title
   */
  @Input() description?: string;

  /**
   * Link for the "Mehr Lesen" button
   */
  @Input() link?: string;

  /**
   * Appointment of the card shown below the title
   * Use only if it is appointment card
   */
  @Input() appointment?: Date | string;

  /**
   * Threshold for trimming text - Default: 15
   */
  @Input() threshold: number = 15;

  /**
   * If the card is an event card
   * Use only if it is event card
   * Eventcards have a different layout - where the image is on the left side
   */
  @Input() event?: boolean;

  /**
   * Mobile layout without image
   */
  @Input() small?: boolean;
  
  constructor() {}

  /**
   * Trims text to a certain length
   * @param text text to trim
   */
  public trim(text?: string) {
    if(!text) return text;
    if(this.threshold < 0) return text;

    const words = text.split(/\s/g);
    return (words.length > this.threshold ? [...words.splice(0, this.threshold), '...'] : words).join(' ');
  }
}
