import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { IDog } from 'src/app/types/dog.interface';
import { ITrainer } from 'src/app/types/trainer.interface';

@Component({
  selector: 'app-trainer-details',
  templateUrl: './trainer-details.page.component.html',
  styleUrls: ['./trainer-details.page.component.css']
})
export class TrainerDetailsPageComponent {

  private routerSubscription!: Subscription;
  public dogs: IDog[] = [];
  public trainer?: ITrainer;

  constructor(
    private readonly _dataService: DataService, 
    private route: ActivatedRoute,
    ) {}

    public readonly loadingTrainerDetails$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    public async ngOnInit(): Promise<void> {
      this.routerSubscription = this.route.params.subscribe(async(params) => {
        // Dynamically load Data from the API
        const trainer = await this._dataService.fetchTrainer(params['uid']);
        this.trainer = trainer;
        if(trainer) this.loadingTrainerDetails$.next(false);

        const dog = await this._dataService.fetchDogsByTrainer(params['uid']);
        if(dog) this.dogs?.push(...dog);
      });
    }

    /**
   * Ensure that the router subscription is unsubscribed from.
   */
  public async ngOnDestroy(): Promise<void> {
    if(this.routerSubscription) this.routerSubscription.unsubscribe();
  }

  // public trainer = 
  //   {
  //     name: 'Bianca',
  //     avatar: 'https://f005.backblazeb2.com/file/assets-hks/Bianca.jpg',
  //     splashImage: 'https://f005.backblazeb2.com/file/assets-hks/Bianca.jpg',
  //     role: 'Trainerin',
  //     description: 'Ich bin seit 2015 teil des Teams. Alles was mit der Nase zu tun hat, ist meine Leidenschaft!',
  //   };

  // public dogs = [
  //   {
  //     name: 'Yuna',
  //     image: '../../../assets/images/Eowyn.jpg',
  //     breed: 'Chihuahua',
  //     description: 'Der Weg ist das Ziel'
  //   },
  //   {
  //     name: 'Davie',
  //     image: '../../../assets/images/Boe.jpg',
  //     breed: 'Mischling?',
  //     description: 'Sensibler Herzensbrecher'
  //   }
  // ];

  public courses = [];

}
