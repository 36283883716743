    <!-- TODO:
    class="w-full sm:w-[calc(50%-1.25rem)] md:w-[calc(33.333333%-1.666667rem)] lg:w-[calc(25%-1.875rem)] items-stretch"
    -->
    <div *ngIf="!time"  class="w-full h-full rounded-2xl flex flex-col bg-white shadow-around  p-10 gap-5 sm:gap-10">
        <div class="flex flex-col items-center gap-4 sm:gap-5">
            <!-- <img [src]="trainerImage" width="96px" height="96px" class="rounded-full w-24 h-24 object-cover" placeholder="logo.png" [alt]="trainerName"/> -->
            <app-image [src]="trainerImage" width="96px" height="96px" css="rounded-full w-24 h-24 object-cover" [alt]="trainerName"/>
            <p class="text-base text-gray-300">{{ trainerName ?? '{NO_TRAINER}' }}</p>
        </div>

        <hr/>

        <div class="flex flex-col items-center sm:gap-2">
            <p class="font-semibold">{{day}}</p>
            <p class="sm:">{{ (start) ?? '{NO_START}' }} Uhr - {{ (end) ?? '{NO_END}' }} Uhr</p>
        </div>
    </div>

    <!-- TODO:
    class="w-full sm:w-[calc(50%-1.25rem)] md:w-[calc(33.333333%-1.666667rem)] lg:w-[calc(25%-1.875rem)] items-stretch"
    -->
    <div *ngIf="time" class="w-full h-full rounded-2xl flex flex-row sm:flex-col bg-white shadow-around" [routerLink]="link ? linkURL : undefined" [ngClass]="{'cursor-pointer' : link}">
        <!-- <img [src]="image" width="96px" height="96px" class="hidden sm:block w-full object-cover rounded-2xl aspect-video"  placeholder="logo.png" [alt]="title"/> -->
        <div class="hidden sm:block">
            <app-image [src]="image" [mobileSrc]="mobileImage" width="96px" height="96px" css="w-full object-cover rounded-2xl aspect-video" [alt]="title"/>
        </div>
        <div class="hidden sm:flex h-full flex-col justify-between gap-6 p-10">
           <div class="flex flex-col gap-6">
                    <h1 class="text-center text-2xl font-normal">{{ (start) }} - {{ (end) }} Uhr</h1>
                    <p class="text-base font-thin text-center">{{ title }}</p>
            </div>
            <a [routerLink]="linkURL" class="text-center text-primary-500 hover:text-primary-300 font-bold uppercase"><app-button [limited]="true"> Zum Kurs </app-button></a>
        </div>
        <div class="flex sm:hidden h-full w-full flex-row">
            <div class="flex flex-col justify-center items-center p-8 bg-primary-50 rounded-2xl">
                <h1 class="text-center text-3xl font-bold">{{(start)}}</h1>
                <h1 class="text-center text-3xl font-bold">{{(end)}}</h1>
            </div>
            <div class="w-full flex flex-col p-6 justify-center items-center gap-3 overflow-hidden">
                <p class="w-full text-xl font-thin text-center">{{ title }}</p>
                <a *ngIf="link" [routerLink]="linkURL" class="text-primary-500 text-center hover:text-primary-300 font-bold uppercase underline underline-offset-8"> Zum Kurs </a>
            </div>
        </div>
    </div>