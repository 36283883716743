<app-hero background="../../../../assets/images/neuigkeiten-splash.jpg" mobileBackground="../../../../assets/images/neuigkeiten-splash-mobile.jpg">
    <ng-container overlay>
        <div class="flex flex-col p-8 sm:py-12 lg:pr-16 justify-center h-full">
            <div class="flex flex-col lg:flex-row lg:gap-2 justify-end">
                <h1 class="text-4xl sm:text-6xl font-semibold text-center sm:text-right">Aktuelle</h1>
                <h1 class="text-4xl sm:text-6xl font-semibold text-center sm:text-right">Neuigkeiten</h1>
            </div>

            <div class="flex justify-end">
                <p class="sm:w-3/5 py-6 sm:py-12 font-light text-center sm:text-right">
                    Wann auch immer es etwas Neues gibt, auf dieser Seite erfahren Sie zuerst davon.
                </p>
            </div>
        </div>
    </ng-container>    
</app-hero>

<app-section *ngIf="loading$ | async" css="flex justify-center">
    <div class="scale-150">
        <app-loading></app-loading>
    </div>
</app-section>

<app-section *ngIf="(loading$ | async) == false">
    <div class="flex flex-col gap-8 sm:gap-16">
        <h1 class="text-4xl lg:text-6xl font-semibold">Aktuelle Neuigkeiten</h1>
        <div class="flex flex-wrap w-full gap-10">
            <app-card class="w-full sm:w-[calc(50%-1.25rem)] lg:w-[calc(33.333333%-1.666667rem)] xl:w-[calc(25%-1.875rem)] items-stretch" *ngFor="let post of (news$ | async)" [title]="post.title" [description]="post.text" [link]="'/aktuelles/'+post.id" [image]="post.image" [mobileImage]="post.image_mobile" />
        </div>
    </div>
</app-section>
